import React, { useEffect, useState } from 'react';
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, Typography, Box, Tab, Tabs } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import SelectX from '../../../form/SelectX'
import TextInput from '../../../form/TextInput'
import { ErrorMessage } from "@hookform/error-message";
import { LoadingButton } from "@mui/lab";
import { Departments } from '../../../../api/Endpoints/Departments';
import { VendorPayments } from '../../../../api/Endpoints/VendorPayments';
import { VendorBills } from '../../../../api/Endpoints/VendorBills';
import DateInput from '../../../form/DateInput';
import ReactSelector from 'react-select';
import PropTypes from 'prop-types';
import LineItems from './Tabs/LineItems';
import Transactions from './Tabs/Transactions';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({
    title: yup.string().required('Title is required'),
    vendor: yup.object().required('Vendor is required'),
    vendor_id: yup.string(),
    order_id: yup.string(),
    bill_number: yup.string().required('Bill number is required'),
    invoice_number: yup.string(),
    tax_id: yup.string(),
    description: yup.string(),
    // Additional fields for custom vendor
    vendor_name: yup.string().when('vendor', {
        is: (vendor) => vendor?.id === 'other',
        then: yup.string().required('Vendor name is required')
    }),
    vendor_address: yup.string(),
    vendor_gst: yup.string()
});


// TabPanel component for tab content
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vendor-bill-tabpanel-${index}`}
            aria-labelledby={`vendor-bill-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box pt={2}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vendor-bill-tab-${index}`,
        'aria-controls': `vendor-bill-tabpanel-${index}`,
    };
}

const Create = (props) => {

    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [editValues, setEditValues] = useState({})
    const [tabValue, setTabValue] = useState(0);
    const [isOtherVendor, setIsOtherVendor] = useState(false);

    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };


    const handleClickOpen = () => {
        props.onNew()
    };

    const handleClose = () => {
        setTimeout(() => {
            props.setEditId(undefined)
        }, 400);
        setOpen(false);
    };

    // Watch for changes in the vendor selection
    const selectedVendor = watch('vendor');
    
    useEffect(() => {
        // Check if 'Other' vendor is selected
        if (selectedVendor && selectedVendor.id === 'other') {
            setIsOtherVendor(true);
        } else {
            setIsOtherVendor(false);
        }
    }, [selectedVendor]);

    const onSubmit = (data) => {
        console.log('Submitting form data:', data);
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        
        let dataToSubmit = {
            id: props.editId,
            title: data.title,
            vendor_id: data.vendor.id === 'other' ? 'other' : data.vendor.id,
            vendor_details: data.vendor.id === 'other' ? 
                JSON.stringify({
                    name: data.vendor_name,
                    address: data.vendor_address,
                    gst: data.vendor_gst
                }) : data.vendor_details,
            vendor_reference_id: data.vendor_id,
            order_id: data.order_id,
            bill_number: data.bill_number,
            invoice_number: data.invoice_number,
            tax_id: data.tax_id,
            bill_date: data.bill_date,
            due_date: data.due_date,
            description: data.description
        };
        
        let action;
        if (props.editId > 0) {
            action = VendorBills.update(dataToSubmit);
        } else {
            action = VendorBills.add(dataToSubmit);
        }
        
        action.then(response => {
            console.log("Response:", response.data);
            setFormButtonStatus({
                label: "Submitted",
                loading: false,
                disabled: true,
            });
            
            // Reset form after successful submission
            setTimeout(() => {
                setFormButtonStatus({
                    label: "Submit",
                    loading: false,
                    disabled: false,
                });
            }, 2000);
            
            // Close dialog and update parent component
            props.onUpdate && props.onUpdate();
            setOpen(false);
        }).catch(error => {
            console.error("Error:", error);
            setFormButtonStatus({
                label: "Submit",
                loading: false,
                disabled: false,
            });
        });
    }


    const fetchBusinessCountry = (e) => {

        return Departments.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                let data = [
                    { id: 0, name: 'INDIA' },
                    { id: 1, name: 'UAE' }
                ]
                return data
            } else {
                return response.data.data.data;
            }
        })
    }
    const fetchVendors = (e) => {
        return VendorPayments.vendors({ keyword: e }).then(response => {
            if (typeof response.data.data !== "undefined") {
                // Add 'Other' option to the vendors list
                const vendors = [...response.data.data, { id: 'other', name: 'Other' }];
                return vendors;
            } else {
                return [{ id: 'other', name: 'Other' }];
            }
        })
    }
    const fetchVendorBillDetails = () => {
        setLoading(true);
        VendorBills.getDetails({ id: props.editId })
            .then(response => {
                if (response.data.status === "success") {
                    const data = response.data.data;
                    setValue('title', data.title);
                    if (data.vendor) {
                        setValue('vendor', { id: data.vendor.id, name: data.vendor.name });
                    }
                    setValue('vendor_details', data.vendor_details);
                    setValue('vendor_id', data.vendor_reference_id);
                    setValue('order_id', data.order_id);
                    setValue('bill_number', data.bill_number);
                    setValue('invoice_number', data.invoice_number);
                    setValue('tax_id', data.tax_id);
                    setValue('bill_date', data.bill_date);
                    setValue('due_date', data.due_date);
                    setValue('description', data.description);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching vendor bill details:", error);
                setLoading(false);
            });
    };
    
    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchVendorBillDetails();
            setOpen(true);
        } else if (Number(props.editId) == 0) {
            setOpen(true);
        }

    }, [props.editId])

    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                {props.icon ? <AddIcon style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }} /> : "Create Vendor Bill"}
            </Button>
            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit Vendor Bill" : "Create Vendor Bill"}</DialogTitle>

                <DialogContent>
                    <Box sx={{ width: '100%', mt: 0 }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="vendor bill tabs">
                                <Tab label="Details" {...a11yProps(0)} />
                                <Tab label="Line Items" {...a11yProps(1)} />
                                {/* <Tab label="Transactions" {...a11yProps(2)} /> */}
                            </Tabs>
                        </Box>
                        
                        <TabPanel value={tabValue} index={0}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container>
                                    <Grid container p={1} spacing={2} >
                                        <Grid sm={6} item>
                                            <TextInput control={control}
                                                name="title"
                                                label="Title"
                                                error={errors?.title?.message || false}
                                            />
                                        </Grid>
                                        <Grid item sm={6}>
                                            <SelectX
                                                label={"Vendor"}
                                                options={fetchVendors}
                                                control={control}
                                                name={'vendor'}
                                                defaultValue={watch('vendor')}
                                                error={errors?.vendor?.message || false}
                                            />
                                        </Grid>
                                    </Grid>

                                    {/* Custom vendor fields - shown only when 'Other' is selected */}
                                    {isOtherVendor && (
                                        <Grid container p={1} spacing={2}>
                                            <Grid sm={12} item>
                                                <Typography variant="subtitle1" sx={{ mb: 1, pb: 1, borderBottom: '1px solid #e0e0e0' }}>Custom Vendor Details</Typography>
                                            </Grid>
                                            <Grid sm={6} item>
                                                <TextInput control={control}
                                                    name="vendor_name"
                                                    label="Vendor Name"
                                                    error={errors?.vendor_name?.message || false}
                                                />
                                            </Grid>
                                            <Grid sm={6} item>
                                                <TextInput control={control}
                                                    name="vendor_gst"
                                                    label="Vendor GST"
                                                    error={errors?.vendor_gst?.message || false}
                                                />
                                            </Grid>
                                            <Grid sm={12} item>
                                                <TextInput control={control}
                                                    name="vendor_address"
                                                    label="Vendor Address"
                                                    isMultiline
                                                    row={2}
                                                    error={errors?.vendor_address?.message || false}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid item xs={12}>
                                        <Grid sx={{ p: 1 }} item xs={12}>
                                            <TextInput control={control}
                                                name="vendor_details"
                                                isMultiline
                                                label={"Vendor Details"}
                                                row={2}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container p={1} spacing={2} >
                                        <Grid sm={6} item>
                                            <TextInput control={control}
                                                name="vendor_id"
                                                label="Vendor Id"
                                            />
                                        </Grid>
                                        <Grid item sm={6}>
                                            <TextInput control={control}
                                                name="order_id"
                                                label="Order Id"
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container p={1} spacing={2} >
                                        <Grid sm={12} item>
                                            <TextInput control={control}
                                                name="bill_number"
                                                label="Bill Number"
                                                error={errors?.bill_number?.message || false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container p={1} spacing={2} >
                                        <Grid item sm={6}>
                                            <TextInput control={control}
                                                name="invoice_number"
                                                label="Invoice Number"
                                            />
                                        </Grid>
                                        <Grid sm={6} item>
                                            <TextInput control={control}
                                                name="tax_id"
                                                label="Tax ID"
                                            />
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid container p={1} spacing={2}>
                                        <Grid item sm={6}>
                                            <DateInput
                                                control={control}
                                                name="bill_date"
                                                label="Bill Date"
                                                value={watch('bill_date')}
                                            />
                                        </Grid>
                                        <Grid item sm={6}>
                                            <DateInput
                                                control={control}
                                                name="due_date"
                                                label="Due Date"
                                                value={watch('due_date')}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid sx={{ p: 1 }} item xs={12}>
                                            <TextInput control={control}
                                                name="description"
                                                isMultiline
                                                label={"Description"}
                                                row={4}
                                            />
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid sx={{ p: 1 }} item xs={12}>
                                        <ErrorMessage
                                            errors={errors}
                                            name="multipleErrorInput"
                                            render={({ messages }) =>
                                                messages &&
                                                Object.entries(messages).map(([type, message]) => (
                                                    <p key={type}>{message}</p>
                                                ))
                                            }
                                        />

                                        <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                            variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                                    </Grid>
                                </Grid>
                            </form>
                        </TabPanel>
                        
                        <TabPanel value={tabValue} index={1}>
                            <LineItems isInvoiceTab={false} />
                        </TabPanel>
                        
                        <TabPanel value={tabValue} index={2}>
                            <Transactions />
                        </TabPanel>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>

            </Dialog>
        </div>
    )
}

export default Create;

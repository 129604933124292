import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Backdrop, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, Slide, TextField } from "@mui/material";
import { useState } from 'react';
import DetailView from './Tabs/Details';
import AssigneesHistory from './Tabs/Assignees';
import MaintananceHistory from './Tabs/Maintanace';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ mt: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function content(props) {
    return (
        <div
            hidden={props.value !== props.index}
        >

        </div>
    )

}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs(props) {
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(true);
    const [editId, setEditId] = useState()


    const handleClose = () => {
        props.handleClose(false)
        // setOpen(false); 
    };

    const [details, setDetails] = useState(true)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const showDetails = () => {
        setDetails(true)
    }
    console.log(props.id);

    return (
        <>

            {/* <Dialog
                open={open}
                onClose={handleClose}
            // sx={{ width: '100%', mt: 1 }}
            > */}
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ ml: 2 }}>
                    <Tab label="Details" {...a11yProps(0)} />
                    <Tab label="Assign History" {...a11yProps(1)} />
                    <Tab label="Maintenance History" {...a11yProps(2)} />

                </Tabs>

                <DialogContent>

                    <TabPanel value={value} index={0}>
                        <DetailView detailEdit={props?.detailEdit} setTab={setValue} setEditId={setEditId} id={props.id} handleClose={props.handleClose} />
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <AssigneesHistory setTab={setValue} id={props.id} handleClose={props.handleClose} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <MaintananceHistory setTab={setValue} id={props.id} handleClose={props.handleClose} />
                    </TabPanel>




                </DialogContent>
                <DialogActions>
                    <div style={{ width: '95%', display: 'flex', justifyContent: 'end', marginTop: 5 }}>
                        <Button variant="outlined" onClick={() => props.handleClose(false)} style={{ width: 'fit-content', mr: 7 }} color='error'>
                            Cancel
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}

import { get, post } from './../Config/config';

export const VendorBills = {
    get: (data) => get('/accounts/vendor-bills', { params: data }),
    add: (data) => post('/accounts/vendor-bills/store', data),
    update: (data) => post('/accounts/vendor-bills/update', data),
    getDetails: (data) => get('/accounts/vendor-bills/view', { params: data }),
    delete: (data) => get('/accounts/vendor-bills/delete', { params: data }),
    getLineItems: (data) => get('/accounts/vendor-bills/line-items', { params: data }),
    addLineItem: (data) => post('/accounts/vendor-bills/line-items/store', data),
    updateLineItem: (data) => post('/accounts/vendor-bills/line-items/update', data),
    deleteLineItem: (data) => get('/accounts/vendor-bills/line-items/delete', { params: data }),
    getTransactions: (data) => get('/accounts/vendor-bills/transactions', { params: data }),
    addTransaction: (data) => post('/accounts/vendor-bills/transactions/store', data),
    updateTransaction: (data) => post('/accounts/vendor-bills/transactions/update', data),
    deleteTransaction: (data) => get('/accounts/vendor-bills/transactions/delete', { params: data })
}
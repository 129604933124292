import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useEffect, useMemo, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { DeleteApI } from "../../../../api/Endpoints/Delete";
import { toast } from "react-hot-toast";
import { Selfi } from "../../../../api/Endpoints/Selfie";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Delete({
  ID,
  setID,
  setDeletePopup,
  Callfunc,
  url,
  title,
  showDeletePopup,
  type,
}) {
  const [open, setOpen] = React.useState(false);
  const [formButtonStatus, setFormButtonStatus] = useState({
    label: "Delete",
    loading: false,
    disabled: false,
  });

  const handleClose = () => {
    setID(0);
    setOpen(false);
    setTimeout(() => {
      setDeletePopup(false);
    }, 100);
  };
  console.log("from delte page", ID);
  useEffect(() => {
    if (ID > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [ID]);

  const DeleteHandler = async () => {
    setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });

    try {
      let response;
      if (type) {
        let dataToDelete = {
          id: ID,
          type: type,
        };
        console.log(dataToDelete);
        response = await Selfi.removePhoto(dataToDelete);
      } else {
        response = await DeleteApI.deletedata(`${url}${ID}`);
      }

      console.log(response);
      if (response.data.status == "success") {
        toast.success(`${title} has been successfully removed.`, {
          autoClose: 1000,
          position: "top-center",
        });
        handleClose();
        Callfunc();
      } else {
        toast.error("An error occurred. Please try again later", {
          autoClose: 1000,
          position: "top-center",
        });
      }

      handleClose();
    } catch (error) {
      toast.error("An error occurred. Please try again later.", {
        autoClose: 2000,
        position: "top-center",
      });
      setFormButtonStatus({ label: "Delete", loading: false, disabled: false });
    }
  };

  const memoizedOpen = useMemo(() => open, [open]);

  return (
    <div>
      <Dialog
        open={memoizedOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            transform: "translateY(-75%)",
          },
        }}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this {title}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="inherit">
            Cancel
          </Button>
          <LoadingButton
            onClick={() => DeleteHandler()}
            loading={formButtonStatus.loading}
            disabled={formButtonStatus.disabled}
            variant="outlined"
            color="error"
            type="submit"
          >
            {formButtonStatus.label}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

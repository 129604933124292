import PageHeader from "../../common/page-header"
import { useEffect, useState } from "react"
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid"
import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material"
import CreateInventory from "./create"
import { Inventories } from "../../../../api/Endpoints/Inventory"
import { blue, red } from "@mui/material/colors"
import DeleteMoadal from "./deleteModal"
import moment from "moment"
import BasicTabs from "./TabMenu"
import { useForm } from "react-hook-form"
import { VendorsApi } from "../../../../api/Endpoints/Vendors"
import SelectX from "../../../form/SelectX"
import { categories } from "../../../../api/Endpoints/Category"
import DateInput from "../../../form/DateInput"
import EditIcon from "@mui/icons-material/Edit"
import { Employee } from "../../../../api/Endpoints/Employee"
import { brands } from "../../../../api/Endpoints/Brands"

const Inventory = () => {
  const [refresh, setRefresh] = useState(false)
  const [editId, setEditId] = useState()
  const [pageNumber, setPageNumber] = useState(0)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [rowsDetails, setrowsDetails] = useState({})
  const [detailViewId, setDetailViewId] = useState(false)
  const [deleteId, setDeleteId] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState()
  const [searchKeywordById, setSearchKeywordById] = useState()
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const handleCreate = () => {
    setEditId(0)
    setRefresh(Math.random)
  }

  const handlePageChange = (page) => {
    setPageNumber(page)
  }

  const handleUpdate = (details) => {
    // handleCreate()
    // setEditId()
    // console.log(editId);
    // setrowsDetails(details)
    // console.log(details);
    setPageNumber()
    setPageNumber(0)
  }

  const TitleB = {
    color: "blue",
    cursor: "pointer",
  }

  const fetchList = () => {
    setLoading(true)
    Inventories.list({
      keyword: searchKeyword,
       id: searchKeywordById,
      page: Number(pageNumber) + 1,
      category_id: watch('category')?.id,
      vendor_id: watch('vendor')?.id,
      brand_id: watch('brand')?.id,
      from: watch('from') && moment(watch('from')).format('YYYY-MM-DD'),
      to: watch('to') && moment(watch('to')).format('YYYY-MM-DD'),
      using_by: watch('assign_to')?.id,
      sort_field:sortField,
      sort_order:sortOrder,
      limit:50
    }).then((response) => {
      setList(response.data.data)
      console.log(response.data.data);
      
      setLoading(false)
    })
  }

  const handleSortModelChange = (model) => {
    if (model.length > 0) {
      const { field, sort } = model[0];
      setSortField(field);
      setSortOrder(sort);
    } else {
      setSortField(null);
      setSortOrder(null);
    }
    fetchList(); 
  };

  const handleEdit = (id) => {
    setRefresh(Math.random)
    setEditId(parseInt(id))
  }

  const {
    watch,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm()

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 60,
      renderCell: (params) => (
        <p>{params.row.id}</p>
        // <p  onClick={viewTabs}>{params.row.title}</p>
      ),
      sortable:true,
    },
    {
      field: "name",
      headerName: "Title",
      width: 350,
      renderCell: (params) => (
        <p style={TitleB} onClick={() => DetailViewOpen(params.id)}>
          {params.row.name}
        </p>
        // <p  onClick={viewTabs}>{params.row.title}</p>
      ),
      sortable:true,
    },
    {
      field: 'vendor',
      headerName: "Vendor",
      width: 180,
      renderCell: (params) => <>{params.row.vendor?.name}</>,
      sortable:true,
    },
    {
      field: "brand",
      headerName: "Brand",
      width: 180,
      renderCell: (params) => <>{params.row.vendor?.brand_name ? params.row.vendor?.brand_name:"" }</>,
      sortable:true,
    },
    {
      field: "category",
      headerName: "Category",
      width: 150,
      renderCell: (params) => <>{params.row.category?.name}</>,
      sortable:true,
    },
    {
      field: "assigned_to",
      headerName: "Assigned To",
      width: 150,
      renderCell: (params) => <>{params.row.current_user?.name}</>,
      sortable:true,
    },
    {
      field: "purchased_on",
      headerName: "Purchased Date",
      width: 100,
      renderCell: (params) => (
        <>{params.value ? moment(params.value).format("DD-MM-YYYY") : ""}</>
      ),
      sortable:true,
    },
    {
      field: "edit",
      headerName: "",
      width: 60,
      renderCell: (params) => (
        <Button onClick={() => handleEdit(params.id)}>
          <EditIcon />
        </Button>
      ),
    },
  ]

  const color = red[400]
  const Bstyle = {
    bgcolor: color,
    height: "30px",
    color: "white",
    marginLeft: "auto",
    "&:hover": {
      color: "black",
      backgroundColor: "lightblue",
    },
  }

  const editColor = blue[400]
  const EditBstyle = {
    bgcolor: editColor,
    height: "30px",
    color: "white",
    marginLeft: "auto",
    "&:hover": {
      color: "black",
      backgroundColor: "lightblue",
    },
  }

  const DetailViewOpen = (id) => {
    setDetailViewId(id)
  }

  useEffect(() => {
    fetchList()
  }, [pageNumber, searchKeyword,searchKeywordById,sortField,sortOrder, watch('category'), watch('vendor'), watch('from'), watch('to'), watch('assign_to'),watch('brand')])

  const handleSearchEntryByName = (event) => {
    setSearchKeyword(event.target.value)
  }
  const handleSearchEntryById = (event) => {
    setSearchKeywordById(event.target.value)
  }
  const fetchVendor = (e) => {
    return VendorsApi.list({ keyword: e }).then((response) => {
      if (typeof response.data.data.data !== "undefined") {
        return response.data.data.data
      } else {
        return []
      }
    })
  }
  const fetchCategory = (e) => {
    return categories.list({ keyword: e }).then((response) => {
      if (typeof response.data.data.data !== "undefined") {
        return response.data.data.data
      } else {
        return []
      }
    })
  }
  const fetchBrands = (e) => {
    return brands.list({ keyword: e }).then((response) => {
      if (typeof response.data.data !== "undefined") {
        //console.log(response.data.data);
        
        return response.data.data
      } else {
        return []
      }
    })
  }
  
  const fetchEmployees = (e) => {
    return Employee.get({ keyword: e }).then(response => {
      if (typeof response.data.data.data !== 'undefined') {
        return response.data.data.data;
      } else {
        return [];
      }
    });
  };

  const handleClearSearch = () => {
    reset()
    setValue("vendor", null)
    setValue("category", null)
    setValue("assign_to", null)
    setValue("from", null)
    setValue("to",null)
    setValue("assign_to",null)
    setValue("brand",null)
    setSearchKeyword(null)
    setSearchKeywordById(null)
  }

  return (
    <>
      {detailViewId && (
        <BasicTabs id={detailViewId} handleClose={setDetailViewId} />
      )}

      {deleteId && (
        <DeleteMoadal
          id={deleteId}
          handleOpen={setDeleteId}
           refresh={handleUpdate}
          statement={"Inventory"}
        />
      )}

      <PageHeader title={"Inventory"} />

     <Card sx={{ p: 2 }} variant="outlined">
  <Grid container spacing={2}>
    <Grid item xs={12} md={2.4}>
    <br/>
      <TextField
        id="outlined-name"
        autoFocus
        label="Search"
        name="search"
        fullWidth
        placeholder="Search by id"
        value={searchKeywordById}
        onChange={handleSearchEntryById}
        sx={{ '& .MuiInputBase-root': { height: '40px' } }} 
      />
    </Grid>
    <Grid item xs={12} md={2.4}>
      <br/>
      <TextField
        id="outlined-name"
        label="Search"
        name="search"
        fullWidth
        placeholder="Search by title"
        value={searchKeyword}
        onChange={handleSearchEntryByName}
        sx={{ '& .MuiInputBase-root': { height: '40px' } }} 
      />
    </Grid>
    <Grid item xs={12} md={2.4}>
      <SelectX
        key={refresh * 0.2}
        label={"Vendor"}
        options={fetchVendor}
        control={control}
        name={"vendor"}
        defaultValue={watch("vendor")}
      
      />
    </Grid>
    <Grid item xs={12} md={2.4}>
      <SelectX
        key={refresh * 0.2}
        label={"Category"}
        options={fetchCategory}
        control={control}
        name={"category"}
        defaultValue={watch("category")}
        
      />
    </Grid>
    <Grid item xs={12} md={2.4}>
      <SelectX
        key={refresh * 0.2}
        label={"Assign To"}
        options={fetchEmployees}
        control={control}
        name={"assign_to"}
        defaultValue={watch("assign_to")}
      
      />
    </Grid>
    <Grid item xs={12} md={2.4}>
      <SelectX
        key={refresh * 0.2}
        label={"Brands"}
        options={fetchBrands}
        control={control}
        name={"brand"}
        defaultValue={watch("brand")}
       
      />
    </Grid>

    <Grid item xs={12} md={2.4}>
      <DateInput
        control={control}
        name="from"
        label="From"
        value={watch("from")}
      />
    </Grid>
    <Grid item xs={12} md={2.4}>
      <DateInput
        control={control}
        name="to"
        label="To"
        value={watch("to")}
      />
    </Grid>
    <Grid item xs={12} md={2.4} sx={{ display: "flex", alignItems: "end" }}>
      
      <Button
        variant="outlined"
        fullWidth
        onClick={handleClearSearch}
      >
        Clear
      </Button>
    </Grid>
    <Grid item xs={12} md={2.4} sx={{ display: "flex", alignItems: "end" }}>
    
      <CreateInventory
        rowdetails={rowsDetails}
        key={refresh}
        onNew={handleCreate}
        onUpdate={handleUpdate}
        editId={editId}
        setEditId={setEditId}
       
      />
    </Grid>
  </Grid>
</Card>



      <Card sx={{ m: 2 }} variant="outlined">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {list && (
          <DataGrid
           disableColumnSorting 
            paginationMode="server"
            rows={list?.data}
            columns={columns}
            page={pageNumber}
            pageSize={50}
            rowHeight={30}
            rowCount={list?.total}
            rowsPerPageOptions={[50]}
            onPageChange={handlePageChange}
            autoHeight
            density={"comfortable"}
            onSortModelChange={handleSortModelChange}
            
          />
        )}
      </Card>
    </>
  )
}

export default Inventory

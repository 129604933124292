import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Slide,
  DialogActions,
  DialogContent,
  Select,
  MenuItem,
  FormControl,
  Grid,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import ReactSelector from "react-select";
import axios from "axios";
import {Employee} from "../../../../api/Endpoints/Employee";
import SelectX from "../../../form/SelectX";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import { ClientProfile } from "../../../../api/Endpoints/ClientProfile";
import { VendorsApi } from "../../../../api/Endpoints/Vendors";
import { TransactionsApi } from "../../../../api/Endpoints/Transactions";

function LedgerView({ id,setLedgerViewId, openLedgerView, setOpenLedgerView }) {
  const scheme = yup.object().shape({
    // employee: yup.object(),
    // client:yup.object(),
    // vendor:yup.object(),
    // provider_type:yup.string(),
    // amount:yup.string(),
    // title:yup.string(),
    // remarks:yup.string(),
    // type:yup.string(),
    // category:yup.object()
  }) 
  const { control, handleSubmit,watch,setValue, formState: { errors } } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
  const [date, setDate] = useState(new Date());
  const [title, setTitle] = useState("");
  const [credit, setCredit] = useState("");
  const [debit, setDebit] = useState("");
  const [ledgerId, setLedgerId] = useState("");
  const [data, setData] = useState({});

  const providerType = [
    { value: 'vendor', label: 'Vendor' },
    { value: 'client', label: 'Client' },
    { value: 'employee', label: 'Employee' },
  ];
 
  const fetchEmployee = (e) => {
    return Employee.get({ keyword: e }).then((response) => {
      console.log(response)
      if (typeof response.data.data.data !== "undefined") {
        console.log(response.data.data.data);
        
        return response.data.data.data
      } else {
        return []
      }
    })
  }

  const fetchClient = (e) => {
    console.log("working");
    
    return ClientProfile.get({ keyword: e }).then((response) => {
      if (typeof response.data.data !== "undefined") {
        
        return response.data.data
      } else {
        return []
      }
    })
  }

  const fetchVendor = (e) => {
    return VendorsApi.list({ keyword: e }).then((response) => {
      console.log(response)
      if (typeof response.data.data.data !== "undefined") {
        return response.data.data.data
      } else {
        return []
      }
    })
  }

  const fetchTransactionsDetails = async () => {
    let Transactions = await TransactionsApi.getDetails({ id: id })
    if (Transactions.data.status === "success") {
      let data = Transactions.data.data
      setValue('remarks',data?.remarks);
      setValue('transaction_type',data?.transaction_type);
      setValue('amount',data?.amount);
      setValue('title',data?.title);
      


      
    }
  }

   useEffect(() => {
     fetchTransactionsDetails()
   },[])

  // useEffect(() => {
  //   fetchVendor()
  //   fetchClient()
  //   fetchEmployee()
  // },[])

  
  // useEffect(() => {
    
   
  //   console.log(watch('provider_type'));
    

  // }, [watch('provider_type')]);

  const onSubmit = (data) => {
    console.log(data);
  };

  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });

  return (
    <Dialog
      open={openLedgerView}
      onClose={()=>{
        
        setOpenLedgerView(false)
        console.log(openLedgerView);

        setLedgerViewId(null)

        
      
      }}
      // TransitionComponent={Transition}
      PaperProps={{
        sx: {
          width: "50%",
          height: "100%",
          position: "fixed",
          right: 0,
          top: 0,
          bottom: 0,
          m: 0,
          p: 0,
          borderRadius: 0,
          maxHeight: "100%",
        },
      }}
    >
      <DialogTitle>Ledger</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormLabel id="demo-simple-select-label">Provider Type</FormLabel>
              <FormControl fullWidth sx={{zIndex:100}}>
              <Controller
                name="provider_type"
                control={control}
                render={({ field }) => (
                  <ReactSelector
                  options={providerType}
                  value={field.value}
                  onChange={field.onChange}
                  />
                )}
              />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              
              {watch("provider_type")?.value == 'client' &&
              <FormControl fullWidth>
              <SelectX
                      // key={refresh * 0.2}
                      label={"Client"}
                      options={fetchClient}
                      value={watch("client")}
                      control={control}
                      // error={
                      //   errors?.tax_account
                      //     ? errors?.tax_account?.message
                      //     : false
                      // }
                      name={"client"}
                      defaultValue={watch("client")}
                    />
                    </FormControl>
              // <Controller
              //   name="client"
              //   control={control}
              //   render={({ field }) => (
              //     <ReactSelector 
              //     options={fetchClient}
              //     value={field.value}
              //     onChange={field.onChange}
              //     />
              //   )}
              // />
              
              }
              {watch("provider_type")?.value == 'vendor' && (
                <FormControl fullWidth>
                <SelectX
                      label={"Vendor"}
                      options={fetchVendor}
                      value={watch("vendor")}
                      control={control}
                      name={"vendor"}
                      defaultValue={watch("vendor")}
                    />
                    </FormControl>
                    )}
              {watch("provider_type")?.value == 'employee' && (
                <FormControl fullWidth>
                <SelectX
                      label={"Employee"}
                      options={fetchEmployee}
                      value={watch("employee")}
                      control={control}
                      name={"employee"}
                      defaultValue={watch("employee")}
                    />
                    </FormControl>
                    )}

            
             
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="transaction_type"
                  value={watch("transaction_type")}
                  disabled
                >
                  <FormControlLabel
                    value="Credit"
                    control={<Radio />}
                    label="Credit"
                    checked={watch("transaction_type") === "Credit"}
                  />
                  <FormControlLabel
                    value="Debit"
                    control={<Radio />}
                    label="Debit"
                    checked={watch("transaction_type") === "Debit"}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormLabel>Category</FormLabel>
              <FormControl fullWidth>
              <Controller
                name="category"
                control={control}
                render={({ field }) => (
                  <ReactSelector 
                  options={providerType}
                  filterOption={(inputValue, option) => option && option.label.toLowerCase().includes(inputValue.toLowerCase())}
                  value={field.value}
                  onChange={field.onChange}
                  />
                )}
              />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>Amount</FormLabel>
              <FormControl fullWidth>
                <Controller
                  name="amount"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      value={field.value}
                      onChange={field.onChange}
                      
                      type="number"
                      InputProps={{
                        readOnly:true
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormLabel>Title</FormLabel>
              <FormControl fullWidth>
                <Controller
                  name="title"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormLabel>Remarks</FormLabel>
              <FormControl fullWidth>
                <Controller
                  name="remarks"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      value={field.value}
                      multiline
                      onChange={field.onChange}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 4,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Submit
                </Button>

                <Button
                  variant="text"
                  color="primary"
                  onClick={()=>{
                    setOpenLedgerView(false)
                    setLedgerViewId(null)
                  }


                  }
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default LedgerView;

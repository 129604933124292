import { destroy, get, post } from './../Config/config';

export const Selfi = {

    get: (data) => get('/employees/photos', { params: data }),
    add: (data) => post('/employees/photos/store', data),
    getDetails: (data) => get('/employees/photos/view?id=', { params: data }),
    update: (data) => post('/employees/photos/update', data),

    addSelfie: (data) => post('/employees/update-photo', data),
    removePhoto: (data) => post('/employees/remove-photo', data)

}
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import SelectX from "../../../form/SelectX";
import TextInput from "../../../form/TextInput";
import { ErrorMessage } from "@hookform/error-message";
import { LoadingButton } from "@mui/lab";
import { Departments } from "../../../../api/Endpoints/Departments";
import DateInput from "../../../form/DateInput";
import ReactSelector from "react-select";
import { TaxAccount } from "../../../../api/Endpoints/TaxAccount";
import { BusinessAccount } from "../../../../api/Endpoints/BusinessAccount";
import { Currency } from "../../../../api/Endpoints/Currency";
import { Paymentchannels } from "../../../../api/Endpoints/PaymentChannels";
import { toast } from "react-hot-toast";
import { WorkOrders } from "../../../../api/Endpoints/WorkOrders";
import moment from "moment";
import { ring } from "ldrs";
import { de } from "date-fns/locale";
import { Account } from "../../../../api/Endpoints/Account";
import { TaxCode } from "../../../../api/Endpoints/TaxCode";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Default values shown

const scheme = yup.object().shape({
  title: yup.string().required("Title is required"),
  client: yup.object().required("Client is required"),
  client_profile: yup.object().required("Client Profile is required"),
  tax_code: yup.object(),
  department: yup.object().required("Department is required"),
  status: yup.string().required("Status is required"),
  amount: yup
    .number()
    .positive()
    .required("Amount is required and must be positive"),
  foreign_amount: yup
    .number()
    .positive()
    .required("Foreign Amount is required and must be positive"),
  foreign_currency: yup.object().required("Foreign Currency is required"),
});

const CreateWorkOrder = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taxAccount, setTaxAccount] = useState([]);
  const [taxAccount_id, setTaxAccount_id] = useState();
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState(null);
  const [title, setTitle] = useState("");
  const [businessAccount, setBusinessAccount] = useState(null);
  const [taxAccounts, setTaxAccounts] = useState("");
  const [currency, setCurrency] = useState(null);
  const [amount, setAmount] = useState("");
  const [foreignAmount, setForeignAmount] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [paymentChannel, setPaymentChannel] = useState(null);
  const [paymentDate, setPaymentDate] = useState("");
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(null);
  //workorder type
  const [workOrderType, setWorkOrderType] = useState(null);

  const [formButtonStatus, setFormButtonStatus] = useState({
    label: "Submit",
    loading: false,
    disabled: false,
  });

  const handleClickOpen = () => {
    props.onNew();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data) => {
    setFormButtonStatus({ ...formButtonStatus, loading: true });

    // Handle date formatting
    const date = moment(data.payment_date).format("YYYY-MM-DD");

    // Prepare data for submission
    const dataToSubmit = {
      title: data.title, // Get title from form data
      department_id: department,
      client_id: client,
      client_profile_id: data.client_profile?.id,
      tax_code_id: selectedTaxCode?.value,
      status: data.status,
      amount: parseFloat(data.amount) || 0,
      foreign_amount: parseFloat(data.foreign_amount) || 0,
      foreign_currency_id: data.foreign_currency?.id,
      transaction_date: date,
      transaction_type: paymentType,
      description: data.description
    };

    console.log("Data to be submitted:", dataToSubmit);

    try {
      const response = await (props.editId > 0 ? WorkOrders.update(dataToSubmit) : WorkOrders.add(dataToSubmit));
      
      toast.success(response.data.message);
      setFormButtonStatus({
        label: "Submitted",
        loading: false,
        disabled: true,
      });

      reset();
      setOpen(false);
      props.onUpdate();
    } catch (error) {
      console.error("Error submitting:", error);
      toast.error("Internal server error");
    } finally {
      setFormButtonStatus({
        label: "Submit",
        loading: false,
        disabled: false,
      });
    }
  };

  const fetchPaymentChannel = (e) => {
    return Paymentchannels.list({ keyword: e }).then((response) => {
      console.log(response);
      if (typeof response.data.data.data !== "undefined") {
        return response.data.data.data;
      } else {
        return [];
      }
    });
  };

  //department
  const fetchDepartments = (e) => {
    return Departments.get({ keyword: e }).then((response) => {
      console.log(response);
      if (typeof response.data.data.data !== "undefined") {
        setDepartments(response.data.data.data);
        console.log(response.data.data.data);

        console.log({ departments, department });

        return response.data.data.data;
      } else {
        return [];
      }
    });
  };

  const fetchClient = (e) => {
    return Account.get({ keyword: e }).then((response) => {
      console.log(response);
      if (typeof response.data.data.data !== "undefined") {
        console.log(response.data.data.data);

        setClients(response.data.data.data);

        console.log({ clients, client });

        return response.data.data.data;
      } else {
        return [];
      }
    });
  };

  useEffect(() => {
    fetchDepartments();
    fetchClient();
  }, []);

  const fetchCurrency = (e) => {
    return Currency.get({ keyword: e }).then((response) => {
      console.log(response);
      if (typeof response.data.data !== "undefined") {
        return response.data.data;
      } else {
        return [];
      }
    });
  };

  const fetchTransactionsDetails = async () => {
    setLoading(true);
    ring.register();
    let Transactions = await WorkOrders.getDetails({ id: props.editId });
    if (Transactions.data.status === "success") {
      let data = Transactions.data.data;
      setTitle(data.title);
      setBusinessAccount({
        id: data.business_account?.id,
        name: data.business_account?.title,
      });
      setPaymentChannel({
        id: data.payment_channel?.id,
        name: data.payment_channel?.title,
      });
      setCurrency({
        id: data.default_currency?.id,
        name: data.default_currency?.name,
      });
      setTaxAccounts(data.tax_account?.title);
      setTaxAccount_id(data.tax_account?.id);
      setPaymentDate(data.transaction_date);
      setAmount(data.amount);
      setForeignAmount(data.foreign_amount);
      setPaymentType(data.transaction_type);
      setClient(data.client);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (parseInt(props.editId) > 0) {
      fetchTransactionsDetails();
      setOpen(true);
    } else if (Number(props.editId) === 0) {
      setOpen(true);
    }
  }, []);

  const [taxCodes, setTaxCodes] = useState([]);
const [selectedTaxCode, setSelectedTaxCode] = useState(null);

const fetchTaxCodes = (e) => {
  return TaxCode.get({ keyword: e }).then((response) => {
    if (typeof response.data.data.data !== "undefined") {
      setTaxCodes(response.data.data.data);
      return response.data.data.data;
    } else {
      return [];
    }
  });
};

useEffect(() => {
  fetchTaxCodes();
}, []);

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} sx={{ mr: 2 }}>
        Create WorkOrder
      </Button>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50%",
            height: "100%",
            position: "fixed",
            right: 0,
            top: 0,
            bottom: 0,
            m: 0,
            p: 0,
            borderRadius: 0,
            maxHeight: "100%",
          },
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {parseInt(props.editId) > 0 ? "Edit WorkOrder" : "Create WorkOrder"}
        </DialogTitle>

        <DialogContent>
          {loading ? (
            <Box id="loader-circle">
              <l-ring
                size="40"
                stroke="3"
                bg-opacity="0"
                speed="2"
                color="blue"
              />
            </Box>
          ) : (
            <form onSubmit={onSubmit}>
              <Grid container>
                <Grid container spacing={1} p={1} sx={{ mt: 2 }}>
                  <Grid sx={{pb:1}} item xs={12}>
                    <TextInput
                      control={control}
                      name="title"
                      label="Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Grid>
                  <Grid item sx={{pb:1}}  xs={12}>
                    <InputLabel
                      sx={{
                        color: "black",
                        fontSize: "14px",
                        pb: 0.5,
                      }}
                    >
                      Client
                    </InputLabel>
                    <ReactSelector
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      label="Client"
                      options={clients.map((client) => ({
                        value: client.id,
                        label: client.company_name,
                      }))}
                      value={clients.find((option) => option.id === client?.id)}
                      name="client"
                      isClearable={true}
                      defaultValue={client}
                      onChange={(selectedOption) =>
                        setClient(selectedOption?.value)
                      }
                    />
                  </Grid>

                  <Grid item sx={{pb:1}}  xs={12}>
                    <SelectX
                      // key={refresh * 0.2}
                      label={"Client Profile"}
                      options={fetchPaymentChannel}
                      control={control}
                      error={
                        errors?.assign_to?.id
                          ? errors?.assign_to?.id?.message
                          : false
                      }
                      error2={
                        errors?.assign_to?.message
                          ? errors?.assign_to?.message
                          : false
                      }
                      name={"client_profile"}
                      defaultValue={paymentChannel}
                    />
                  </Grid>
                  <Grid item sx={{pb:1}} xs={12}>
                    <InputLabel
                      sx={{
                        color: "black",
                        fontSize: "14px",
                        pb: 0.5,
                      }}
                    >
                      Tax Code
                    </InputLabel>
                    <ReactSelector
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      label="Tax Code"
                      options={taxCodes.map((taxCode) => ({
                        value: taxCode.id,
                        label: taxCode.title,
                      }))}
                      value={taxCodes.find((option) => option.id === selectedTaxCode?.id)}
                      name="tax_code"
                      isClearable={true}
                      onChange={(selectedOption) => {
                        setSelectedTaxCode(selectedOption);
                        setValue("tax_code", selectedOption);
                      }}
                    />
                  </Grid>
                  <Grid container spacing={1} p={1} sx={{ mt: 1, mb: 0 }}>
                    <Grid item xs={6}>
                      <InputLabel
                        sx={{
                          color: "black",
                          fontSize: "14px",
                          pb: 0.5,
                        }}
                      >
                        Department
                      </InputLabel>
                      <ReactSelector
                        styles={{
                          menu: (provided) => ({ ...provided, zIndex: 9999 }),
                        }}
                        label="Department"
                        options={departments.map((department) => ({
                          value: department.id,
                          label: department.name,
                        }))}
                        value={departments.find(
                          (option) => option.id === department?.id
                        )}
                        name="department"
                        isClearable={true}
                        onChange={(selectedOption) => {
                          setDepartment(
                            (selectedOption) => selectedOption?.value
                          );
                          setValue(
                            "department",
                            selectedOption ? selectedOption.value : ""
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={6} sx={{ width: "100%" }}>
                      <InputLabel
                        sx={{
                          color: "black",
                          fontSize: "14px",
                          pb: 0.5,
                        }}
                      >
                        Status
                      </InputLabel>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          labelId="status-select-label"
                          id="status-select"
                          value={watch("status") || ""}
                          label="Status"
                          onChange={(e) => setValue("status", e.target.value)}
                        >
                          {/* <MenuItem value="">
                            <em>Select Status...</em>
                          </MenuItem> */}
                          <MenuItem value="New">New</MenuItem>
                          <MenuItem value="Ready-to-invoice">
                            Ready-to-invoice
                          </MenuItem>
                          <MenuItem value="cancelled">cancelled</MenuItem>
                          <MenuItem value="on-Hold">on-Hold</MenuItem>
                          <MenuItem value="Invoiced">Invoiced</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid container spacing={1} p={1} sx={{ mt: 1, mb: 0 }}>
                      <Grid item xs={4}>
                        <TextInput
                          control={control}
                          name="amount"
                          label="Amount"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <SelectX
                          // key={refresh * 0.2}
                          label={"Foreign Currency"}
                          isClearable={false}
                          options={fetchCurrency}
                          control={control}
                          error={
                            errors?.assign_to?.id
                              ? errors?.assign_to?.id?.message
                              : false
                          }
                          error2={
                            errors?.assign_to?.message
                              ? errors?.assign_to?.message
                              : false
                          }
                          name={"currency"}
                          defaultValue={currency}
                          onChange={(selectedOption) => {
                            setCurrency(selectedOption?.value);
                          }}
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <TextInput
                          control={control}
                          name="foreign_amount"
                          label="Foreign Amount"
                          value={foreignAmount}
                          onChange={(e) => setForeignAmount(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={ workOrderType == 'INTERNAL' ? 6: 12}>
                      <InputLabel
                        sx={{
                          color: "black",
                          fontSize: "14px",
                          pb: 0.5,
                        }}
                      >
                        Work Order Type
                      </InputLabel>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          labelId="status-select-label"
                          id="status-select"
                          // value={watch("status") || ""}
                          value={workOrderType}
                          label="Status"
                          onChange={(e) => setWorkOrderType(e.target.value)}
                        >
                          <MenuItem value="CLIENT">CLIENT</MenuItem>
                          <MenuItem value="INTERNAL">INTERNAL</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {workOrderType === "INTERNAL" && (
                       <Grid item xs={6}>
                       <InputLabel
                         sx={{
                           color: "black",
                           fontSize: "14px",
                           pb: 0.5,
                         }}
                       >
                         Invoiced To
                       </InputLabel>
                       <ReactSelector
                         styles={{
                           menu: (provided) => ({ ...provided, zIndex: 9999 }),
                         }}
                         label="Department"
                         options={departments.map((department) => ({
                           value: department.id,
                           label: department.name,
                         }))}
                         value={departments.find(
                           (option) => option.id === department?.id
                         )}
                         name="department"
                         isClearable={true}
                         onChange={(selectedOption) => {
                           setDepartment(
                             (selectedOption) => selectedOption?.value
                           );
                           setValue(
                             "department",
                             selectedOption ? selectedOption.value : ""
                           );
                         }}
                       />
                     </Grid>
                    )}
                    */}
                  </Grid>
                </Grid>

                <LoadingButton
                  loading={formButtonStatus.loading}
                  disabled={formButtonStatus.disabled}
                  variant="outlined"
                  type="submit"
                  onClick={onSubmit}
                >
                  {formButtonStatus.label}
                </LoadingButton>
              </Grid>
            </form>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateWorkOrder;



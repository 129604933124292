import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  ListItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";

const TAX_RATE = 0.07;

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

const rows = [
  {
    id: 1,
    title: "Website Development Advance",
    po: "SW-IN-PO-0012",
    amount: "INR 12000",
    cgst: "INR 900",
    sgst: "INR 900",
    total: "INR 13800",
    foreign_currency: "AED 6000",
  },
  {
    id: 2,
    title: "Website Development Advance",
    po: "SW-IN-PO-0012",
    amount: "INR 12000",
    cgst: "INR 900",
    sgst: "INR 900",
    total: "INR 13800",
    foreign_currency: "AED 6000",
  },
  {
    id: 3,
    title: "Website Development Advance",
    po: "SW-IN-PO-0012",
    amount: "INR 12000",
    cgst: "INR 900",
    sgst: "INR 900",
    total: "INR 13800",
    foreign_currency: "US$ 6000",
  },
];

const invoiceSubtotal = subtotal(rows);
const invoiceTaxes = TAX_RATE * invoiceSubtotal;
const invoiceTotal = invoiceTaxes + invoiceSubtotal;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Edit({ open, setOpen }) {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const  [openWorkOrder,setOpenWorkOrder] = useState(false);

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        PaperProps={{
          sx: {
            width: "40%",
            height: "100%",
            position: "fixed",
            right: 0,
            top: 0,
            bottom: 0,
            m: 0,
            p: 0,
            borderRadius: 0,
            maxHeight: "100%",
          },
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
       
      >
        {/* <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
              Edit Invoice
            </Typography>
            <Button
              autoFocus
              color="inherit"
              sx={{ fontSize: "20px" }}
              onClick={handleClose}
            >
              save
            </Button>
          </Toolbar>
        </AppBar> */}
         
        <Box sx={{ paddingLeft: "20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
            }}
          >
            <Typography variant="h4">
              EDIT INVOICE: INVOICE NO: ADZ-14
            </Typography>

            <Box
              sx={{
                border: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "20px",
                borderRadius: "5px",
                gap: "10px",
                cursor: "pointer",
              }}
            >
              <EditIcon color={"primary"} fontSize={"large"} />
              <Typography variant="h6">EDIT INVOICE</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems:"center",
              padding: "20px",
              gap:'20px'
            }}
          >
            <Box>
              <Typography variant="h5" sx={{ mt: 2 }} color={"gray"}>
                31 Jan 2024 - Skyline Builders Pvt Ltd
              </Typography>
              <Typography variant="h5" sx={{ textDecoration: "underline" }}>
                INVOICE LINE ITEMS (WORK ORDERS)
              </Typography>
            </Box>
            <Box>
            {/* <Link to={`/dashboard/business-invoices/1/add`} style={{textDecoration:'none',color:'black'}}> */}
              <Box
                sx={{
                  border: "1px solid black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  
                }}
                onClick={() => setOpenWorkOrder(true)}
              >
                
                <AddCircleIcon color={"success"} fontSize={"large"} />
                <Typography variant="h6">ADD WORK ORDERS</Typography>
                
              </Box>

              <AddWorkOrder openWorkOrder={openWorkOrder} setOpenWorkOrder={setOpenWorkOrder} />
              {/* </Link> */}
            </Box>
          </Box>
        </Box>
        <TableContainer component={Paper} sx={{ padding: 3 }}>
          <Table sx={{ minWidth: 700 }} aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCell align="left">TITLE</TableCell>
                <TableCell align="left">PO</TableCell>
                <TableCell>AMOUNT</TableCell>
                <TableCell align="left">CGST</TableCell>
                <TableCell align="left">SGST</TableCell>
                <TableCell align="left">TOTAL</TableCell>
                <TableCell align="left">FOREIGN CURRENCY</TableCell>
                <TableCell align="left">ACTION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.po}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                    <TableCell>{row.cgst}</TableCell>
                    <TableCell>{row.sgst}</TableCell>
                    <TableCell>{row.total}</TableCell>
                    <TableCell sx={{ textAlign: "left" }}>
                      {row.foreign_currency}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left" }}>
                      <DeleteIcon color={"error"} fontSize="medium" />
                    </TableCell>
                  </TableRow>
                );
              })}

              {/* <TableRow>
                <TableCell
                  colSpan={8}
                  sx={{ fontWeight: "bold", textAlign: "left", width: "100%" }}
                >
                  TOTAL:
                </TableCell> */}
              {/* <TableCell
                  colSpan={8}
                  sx={{ fontWeight: "bold", textAlign: "left", width: "100%" }}
                >
                   AMOUNT :
                </TableCell>
                <TableCell
                  colSpan={8}
                  sx={{ fontWeight: "bold", textAlign: "left", width: "100%" }}
                >
                   INR 36000 
                </TableCell> */}
              {/* </TableRow> */}
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", textAlign: "left" }}>
                  AMOUNT :
                </TableCell>

                <TableCell colSpan={7} align="left" sx={{ padding: 2 }}>
                  INR 36000
                </TableCell>
                {/* <TableCell align="right">
                  {ccyFormat(invoiceSubtotal)}
                </TableCell> */}
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", textAlign: "left" }}>
                  CGST :
                </TableCell>
                <TableCell colSpan={7} align="left" sx={{ padding: 2 }}>
                  INR 2700
                </TableCell>
                {/* <TableCell align="right">
                  {ccyFormat(invoiceSubtotal)}
                </TableCell> */}
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", textAlign: "left" }}>
                  SGST :
                </TableCell>
                <TableCell colSpan={7} align="left" sx={{ padding: 2 }}>
                  INR 2700
                </TableCell>
                {/* <TableCell align="right">
                  {ccyFormat(invoiceSubtotal)}
                </TableCell> */}
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <div style={{ border: "2px solid black" }}></div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  colSpan={1}
                  sx={{ fontWeight: "bold", textAlign: "left" }}
                >
                  TOTAL :
                </TableCell>

                <TableCell
                  colSpan={1}
                  sx={{ fontWeight: "bold", textAlign: "left", padding: 2 }}
                >
                  INR 41,400
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Dialog>
    </React.Fragment>
  );
}







const workOrders = {
  ready: [
    { id: 1, name: "Website development - Part 1", amount: 12000 },
    { id: 2, name: "Digital Marketing - Nov 2024", amount: 7000 },
    { id: 3, name: "SEO for October 2024", amount: 6500 },
  ],
  notReady: [
    { id: 4, name: "Website development - Part 2", amount: 120000 },
    { id: 5, name: "Brochure development - final", amount: 6500 },
  ],
};

const AddWorkOrder = ({openWorkOrder,setOpenWorkOrder}) => {
  const [selectedOrder, setSelectedOrder] = React.useState(null);
  const [workOrderType, setWorkOrderType] = useState("CLIENT"); // Default to CLIENT
  const [openInnerDialog, setOpenInnerDialog] = useState(false);
  

  const handleOpen = (order) => {
    setSelectedOrder(order);
    setOpenWorkOrder(true);
  };
  const handleOpen2 = (order) => {
    setSelectedOrder(order);
    setOpenInnerDialog(true);
  };


  const handleClose = () => {
    setOpenWorkOrder(false);
    setSelectedOrder(null);
  };

  const handleClose2 = () => {
    setOpenInnerDialog(false);
  
  };


  return (
    <Dialog  fullScreen
    PaperProps={{
      sx: {
        width: "40%",
        height: "100%",
        position: "fixed",
        right: 0,
        top: 0,
        bottom: 0,
        m: 0,
        p: 0,
        borderRadius: 0,
        maxHeight: "100%",
      },
    }} open={openWorkOrder}  onClose={handleClose}
    TransitionComponent={Transition}
    >
      {/* <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        
      }}> */}


{/* <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={()=>setOpenWorkOrder(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center",width:"100%"}}>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h5">Add Work Order To Invoice</Typography> */}
            {/* <Button
              autoFocus
              color="inherit"
              sx={{ fontSize: "20px" }}
              onClick={()=>setOpenWorkOrder(false)}
            >
              Back
            </Button> */}
            {/* </Box>
          </Toolbar>
        </AppBar> */}

      
      {/* <Button  onClick={()=>setOpenWorkOrder(false)}>Back</Button> */}
      {/* </Box> */}
      <Typography sx={{ ml: 2, flex: 1 }} variant="h4">Add Work Order To Invoice</Typography>
      <DialogContent>
        <Box>
          {/* <Typography variant="h4" gutterBottom>
            ADD WORK ORDER TO INVOICE
          </Typography> */}
          <Box sx={{mb:3}}>
            <Typography variant="h5" sx={{ mt: 2 }}>
              Invoice Date: 31 Jan 2024
            </Typography>
            <Typography variant="h5" sx={{ mt: 1 }} >
              Invoice ID: INV-001
            </Typography>
            <Typography variant="h5" sx={{ mt: 1 }} >
              Invoice Title: Construction Services
            </Typography>
            <Typography variant="h5" sx={{ mt: 1 }} >
              Client Name: Skyline Builders Pvt Ltd
            </Typography>
            <Typography variant="h5" sx={{ mt: 1 }} >
              Client Profile: Construction Company
            </Typography>
            {/* <Typography variant="h5" sx={{ mt: 2, textDecoration: "underline" }}>
              INVOICE LINE ITEMS (WORK ORDERS)
            </Typography> */}
          </Box>

          {/* READY TO INVOICE */}
          <Typography variant="h6" sx={{ textDecoration: "underline" }}>
            READY TO INVOICE:
          </Typography>
          <List>
            {workOrders.ready.map((order) => (
              <ListItem
                key={order.id}
                secondaryAction={
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpen2(order)}
                  >
                    <AddIcon color={"inherit"} />
                  </Button>
                }
              >
                <ListItemText
                  primary={order.name}
                  secondary={`INR ${order.amount}`}
                />
              </ListItem>
            ))}
          </List>

          {/* NOT READY TO INVOICE */}
          <Typography variant="h6" sx={{ mt: 3, textDecoration: "underline" }}>
            NOT READY TO INVOICE:
          </Typography>
          <List>
            {workOrders.notReady.map((order) => (
              // <ListItem
              //   key={order.id}
              //   secondaryAction={
              //     <Button
              //       variant="contained"
              //       color="primary"
              //       onClick={() => handleOpen(order)}
              //     >
              //       <AddIcon color={"inherit"} />
              //     </Button>
              //   }
              // >
                <ListItemText
                  primary={order.name}
                  secondary={`INR ${order.amount}`}
                />
              // </ListItem>
            ))}
          </List>

          {/* MODAL */}
         
          <Dialog
            open={openInnerDialog}
            onClose={handleClose2}
            fullWidth
            sx={{
              position: "fixed",
              left: 0,
              right: 0,
            }}
          >
            <DialogTitle>ADD WORK ORDER TO INVOICE</DialogTitle>
            <DialogContent>
              {selectedOrder && (
                <Box>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Item"
                    value={selectedOrder.name}
                    disabled
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Description"
                    placeholder="Enter description"
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Amount"
                    value={selectedOrder.amount}
                    disabled
                  />
                  {/* <Select
                    fullWidth
                    margin="normal"
                    value={workOrderType}
                    onChange={(e) => setWorkOrderType(e.target.value)}
                  >
                    <MenuItem value="CLIENT">Client</MenuItem>
                    <MenuItem value="INTERNAL">Internal</MenuItem>
                  </Select> */}

                  {/* {workOrderType === "CLIENT" && (
                    <> */}
                      <TextField
                        fullWidth
                        margin="normal"
                        label="PO Number"
                        placeholder="Enter PO Number"
                      />
                      <TextField
                        fullWidth
                        margin="normal"
                        label="CGST"
                        placeholder="Enter CGST"
                      />
                      <TextField
                        fullWidth
                        margin="normal"
                        label="SGST"
                        placeholder="Enter SGST"
                      />
                      <TextField
                        fullWidth
                        margin="normal"
                        label="IGST"
                        placeholder="Enter IGST"
                      />
                      <TextField
                        fullWidth
                        margin="normal"
                        label="VAT"
                        placeholder="Enter VAT"
                      />
                    {/* </>
                  )} */}

                  {/* {workOrderType === "INTERNAL" && (
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Invoiced To"
                      placeholder="Enter department name"
                    />
                  )} */}
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose2} color="secondary">
                Cancel
              </Button>
              <Button variant="contained" color="primary">
                ADD WORK ORDER TO INVOICE
              </Button>
            </DialogActions>
          </Dialog>
          </Box>
       
      </DialogContent>
    </Dialog>
  );
};



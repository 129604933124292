import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import AssignPopup from '../../../../../components/popup/AssignPopup';
import { AssignUser } from '../../../../../api/Endpoints/Assign';
import moment from 'moment';
import AssignNotesPopup from '../../../../../components/popup/AssignNotes';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';

function AssigneesHistory({ id }) {
    const [editID, setEditId] = useState(0);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [unAssignId, setUnAssignId] = useState(0);
    const [refresh, setRefresh] = useState(0);
    const [showNotesPopup, setShowNotesPopup] = useState(false);
    const [notes, setNotes] = useState({
        assign_note: '',
        releas_note: ''
    });

    const handleAssignBtnClick = () => {
        setEditId(undefined);
    };

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            renderCell: (params) => <>{params.row?.assignee?.name}</>,
        },
        {
            field: 'assigned_date',
            headerName: 'Assigned Date',
            width: 150,
            renderCell: (params) => <>{moment(params.row?.assigned_on).format('DD-MM-YYYY')}</>,
        },
        {
            field: 'release_date',
            headerName: 'Released Date',
            width: 130,
            renderCell: (params) => <>
                {params.row?.released_on && moment(params.row?.released_on).format('DD-MM-YYYY')}
            </>,
        },
        {
            field: 'note',
            headerName: 'Notes',
            width: 100,
            renderCell: (params) => {
                if (params?.row?.assign_note) {
                    return (
                        <Button onClick={() => showNotes({ assign_note: params.row.assign_note, release_note: params.row.release_note })}>
                            <TextSnippetOutlinedIcon />
                        </Button>
                    );
                } else {
                    return null;
                }
            },
        }

    ];

    const unAssignHandleClick = () => {
        setUnAssignId(data?.current_assignee?.id);
    };

    useEffect(() => {
        fetchAssignHistoryTable();
    }, [id, refresh]);

    const fetchAssignHistoryTable = async () => {
        setLoading(true);
        const res = await AssignUser.getAssignHistoryTable({ id: id });
        setData(res?.data.data);
        setLoading(false);
    };

    const handleUpdate = () => {
        setRefresh(Math.random);
    };

    const showNotes = (Notes) => {

        setNotes({
            assign_note: Notes?.assign_note,
            release_note: Notes?.release_note
        });
        setShowNotesPopup(true);
    };

    return (
        <>
            {!loading ? (
                <Box sx={{ height: 500, width: '100%' }}>
                    {data?.current_user?.name ? (
                        <Grid display={'flex'} justifyContent={'end'} marginLeft={3}>
                            <Button variant='contained' onClick={unAssignHandleClick} sx={{
                                background: 'rgb(20,184,166)',
                                '&:hover': {
                                    background: 'rgb(20,184,166)',
                                }
                            }}>UNAssign</Button>
                        </Grid>
                    ) : (
                        <Grid display={'flex'} justifyContent={'end'} marginLeft={3}>
                            <Button variant='contained' onClick={handleAssignBtnClick} >Assign</Button>
                        </Grid>
                    )}

                    <AssignPopup editID={editID} setEditId={setEditId} itemId={id} UnassignId={unAssignId} setUnAssignId={setUnAssignId} onUpdate={handleUpdate} />

                    {data && (
                        <DataGrid
                            rows={data?.assignees}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            disableSelectionOnClick
                        />
                    )}

                    {showNotesPopup && (
                        <AssignNotesPopup
                            Open={showNotesPopup}
                            setOpen={setShowNotesPopup}
                            assign_note={notes.assign_note}
                            release_note={notes.release_note}
                        />
                    )}
                </Box>
            ) : (
                <Box
                    sx={{
                        width: "100%",
                        height: "50%",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                    }}
                >
                    <CircularProgress sx={{ color: "blue" }} />
                </Box>
            )}
        </>
    );
}

export default AssigneesHistory;

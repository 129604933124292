import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Slide,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Box,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { yupResolver } from "@hookform/resolvers/yup/dist/yup"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import SelectX from "../../../form/SelectX"
import TextInput from "../../../form/TextInput"
import { ErrorMessage } from "@hookform/error-message"
import { LoadingButton } from "@mui/lab"
import { Departments } from "../../../../api/Endpoints/Departments"
import DateInput from "../../../form/DateInput"
import ReactSelector from "react-select"
import { TaxAccount } from "../../../../api/Endpoints/TaxAccount"
import { BusinessAccount } from "../../../../api/Endpoints/BusinessAccount"
import { Currency } from "../../../../api/Endpoints/Currency"
import { Paymentchannels } from "../../../../api/Endpoints/PaymentChannels"
import { toast } from "react-hot-toast"
import { TransactionsApi } from "../../../../api/Endpoints/Transactions"
import {TransactionsCategory} from '../../../../api/Endpoints/TranscactionCategory'
import moment from "moment"
import { ring } from "ldrs"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

// Default values shown



const CreateTransactions = (props) => {

  const paymentOptions = [
    { value: "Debit", label: "Debit" },
    { value: "Credit", label: "Credit" },
    { value: "Ledger", label: "Ledger" },
  ]
   //form validation schema
   const scheme = yup.object().shape({
    title: yup.string().required("Title is required"),
    business_account: yup.object().required("Business Account is required").nullable(),
    tax_account: yup.object().required('Tax Account is required').nullable(),
    amount: yup.string().required('Amount is required'),
    payment_type: yup
      .string()
      .oneOf(paymentOptions.map(option => option.value), 'Payment Type is required')
      .required('Payment Type is required'),
    payment_channel: yup.object().required('Payment Channel is required').nullable(),
    payment_date: yup.date().required('Payment Date is required'),
    description: yup.string().nullable(),
    // foreign_currency: yup.object().nullable(),
    // foreign_amount: yup.number().nullable(),
    // transaction_reference: yup.string().nullable(),
    category: yup.object().nullable().required('Category is required'),
  })
  
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors,isDirty,isValid },
    control,
    setValue,
    getValues,
    reset,
  } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" })

 

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [taxAccount, setTaxAccount] = useState([])
  const [taxAccount_id, setTaxAccount_id] = useState()

  const [businessAccount,setBusinessAccount] = useState([]);
  const [businessAccount_id,setBusinessAccount_id] = useState();
  const [refresh, setRefresh] = useState(false)

  const [formButtonStatus, setFormButtonStatus] = useState({
    label: "Submit",
    loading: false,
    disabled: false,
  })

  const handleClickOpen = () => {
    props.onNew()
  }

  const handleClose = () => {
    setOpen(false)
  }

  console.log(errors?.payment_type?.message);
  // console.log({isDirty,isValid});

  
  

  const onSubmit = (data) => {
    console.log("submitted");
    
    setFormButtonStatus({ ...formButtonStatus, loading: true })
    console.log(data,'send');
    

    let date = moment(data.payment_date).format("YYYY-MM-DD")

    let dataToSubmit;
    
      dataToSubmit= {
        id: props?.editId ,
        title: data?.title||data?.transaction_reference      ,
        account_business_accounts_id: data.business_account?.id,
        account_payment_channels_id: data.payment_channel?.id,
        account_tax_accounts_id: data?.tax_account?.id,
        default_currency_id: data.business_account?.default_currency_id,
        amount: data.amount,
        transaction_date: date,
        transaction_type: data.payment_type,
        description: data.description,
        bank_ref_number:data?.transaction_reference,
        category_id:data?.category?.id
      }
   
    
   
    console.log(dataToSubmit);
    

    let action

    if (props.editId > 0) {
      action = TransactionsApi.update(dataToSubmit)
    } else {
      action = TransactionsApi.add(dataToSubmit)
    }
    action
      .then((response) => {
        setFormButtonStatus({
          label: "Submitted",
          loading: false,
          disabled: true,
        })

        setFormButtonStatus({
          label: "Create",
          loading: false,
          disabled: false,
        })
        toast.success(response.data.message)
        props.onUpdate()
        setOpen(false)
      })
      .catch((errors) => {
        console.log(errors)
        toast.error("Internal server error")

        setFormButtonStatus({
          label: "Create",
          loading: false,
          disabled: false,
        })
      })
  }

  const fetchPaymentChannel = (e) => {
    return Paymentchannels.list({ keyword: e }).then((response) => {
      console.log(response)
      if (typeof response.data.data.data !== "undefined") {
        return response.data.data.data
      } else {
        return []
      }
    })
  }

  const fetchTransactionCategory = (e) => {
    return TransactionsCategory.list({ keyword: e }).then((response) => {
      console.log(response.data.data,'transaction category')
      if (typeof response.data.data !== "undefined") {
        return response.data.data
      } else {
        return []
      }
    })
  }




  const fetchTaxAccounts = (e) => {
    return TaxAccount.get({ keyword: e }).then((response) => {
      console.log(response)
      if (typeof response.data.data.data !== "undefined") {
        setTaxAccount(response.data.data.data)
        console.log(response.data.data.data);
        
        return response.data.data.data
      } else {
        return []
      }
    })
  }

  const fetchBusinessAccount = (e) => {
   return BusinessAccount.get({ keyword: e }).then((response) => {
      console.log(response)
      if (typeof response.data.data.data !== "undefined") {
        setBusinessAccount(response.data.data.data)
        console.log(response.data.data.data);
        
        return response.data.data.data
      } else {
        return []
      }
    })
  }

  const fetchCurrency = (e) => {
    return Currency.get({ keyword: e }).then((response) => {
      console.log(response)
      if (typeof response.data.data !== "undefined") {
        return response.data.data
      } else {
        return []
      }
    })
  }

  const fetchTransactionsDetails = async () => {
    setLoading(true)
    ring.register()
    let Transactions = await TransactionsApi.getDetails({ id: props.editId })
    if (Transactions.data.status === "success") {
      let data = Transactions.data.data
      setValue("title", data.title)
      setValue("business_account", {
        id: data.business_account?.id,
        name: data.business_account?.title,
        default_currency_id: data.business_account?.default_currency_id
      })
      setValue("payment_channel", {
        id: data.payment_channel?.id,
        name: data.payment_channel?.title,
      })
      setValue("currency", {
        id: data.default_currency?.id,
        name: data.default_currency?.name,
      })
      setValue("foreign_currency", {
        id: data.foreign_currency?.id,
        name: data.foreign_currency?.name,
      })
      setValue("foreign_amount", data.foreign_amount)
      setValue("category", {
        id: data.category?.id,
        name: data.category?.title
      })
      console.log("Setting tax_accounts value:", {
        id: data.tax_account?.id,
        name: data.tax_account?.title
      });
      setValue("tax_account", {
        id: data.tax_account?.id,
        name: data.tax_account?.title
      })
      setTaxAccount_id(data.tax_account?.id)
      setValue("payment_date", data.transaction_date)
      setValue("amount", data.amount)
      setValue("payment_type", data.transaction_type)
      setValue("description", data.description)
      setValue('transaction_reference',data?.bank_ref_number)
      // setValue('remarks',data?.remarks)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchTaxAccounts()
    fetchBusinessAccount()
  }, [])

  useEffect(() => {
    if (parseInt(props.editId) > 0) {
      fetchTransactionsDetails()
      setOpen(true)
    } else if (Number(props.editId) === 0) {
      setOpen(true)
    }
  }, [])


  
  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen} sx={{ mr: 2 }}>
        Create Transaction
      </Button>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50%",
            height: "100%",
            position: "fixed",
            right: 0,
            top: 0,
            bottom: 0,
            m: 0,
            p: 0,
            borderRadius: 0,
            maxHeight: "100%",
          },
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {parseInt(props.editId) > 0
            ? "Edit Transaction"
            : "Create Transaction"}
        </DialogTitle>

        <DialogContent>
          {loading ? (
            <Box id="loader-circle">
              <l-ring
                size="40"
                stroke="3"
                bg-opacity="0"
                speed="2"
                color="blue"
              />
            </Box>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
           <Grid container spacing={2} p={2}>
  <Grid container spacing={2}>

    <Grid item xs={12} sm={6}>
      <SelectX
        label="Business Account"
        options={fetchBusinessAccount}
        control={control}
        error={errors?.business_account?.message || false}
        name="business_account"
        defaultValue={watch("business_account")}
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <SelectX
        label="Tax Account"
        options={fetchTaxAccounts}
        control={control}
        error={errors?.tax_account?.message || false}
        name="tax_account"
        defaultValue={watch("tax_account")}
      />
    </Grid>
  </Grid>

  <Grid container spacing={2} sx={{ mt: 2 }}>
    {/* Second Row */}
    <Grid item xs={12} sm={6}>
      <SelectX
        label="Payment Channel"
        options={fetchPaymentChannel}
        control={control}
        error={errors?.payment_channel?.message || false}
        name="payment_channel"
        defaultValue={watch("payment_channel")}
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <InputLabel
        sx={{
          color: "black",
          fontSize: "14px",
          pb: 0.5,
        }}
      >
        Payment Type
      </InputLabel>
      <ReactSelector
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        options={paymentOptions}
        value={paymentOptions.find((option) => option.label === watch("payment_type"))}
        name="payment_type"
        onChange={(selectedOption) =>
          setValue("payment_type", selectedOption?.value || "")
        }
      />

    {errors?.payment_type && <Typography color={'#ec4c47'} fontSize={'0.75rem'} variant="subtitle2" component={'span'}>{errors.payment_type.message}</Typography>}
    </Grid>
  </Grid>

  <Grid container spacing={2} sx={{ mt: 2 }}>

    <Grid item xs={12} sm={6}>
      <TextInput
        control={control}
        name="title"
        label="Title"
        value={watch("title")}
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <SelectX
        label="Category"
        options={fetchTransactionCategory}
        control={control}
        name="category"
        value={watch("category")}
        defaultValue={watch("category")}
        error={errors?.category?.message || false}
        onChange={(selectedOption) => {
          setValue("category", selectedOption);
        }}
      />
    </Grid>
  </Grid>

  <Grid container spacing={1} p={1} sx={{ mt: 1 }}>
                  <Grid item xs={6}>
                    <TextInput
                      control={control}
                      isMultiline
                      row={4}
                      name="transaction_reference"
                      label="Transaction Reference"
                      value={watch("transaction_reference")}
                    />
                   
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      control={control}
                      isMultiline
                      row={4}
                      name="description"
                      label="Description"
                      value={watch("description")}
                    />
                   
                  </Grid>
                </Grid>

  <Grid container spacing={2} sx={{ mt: 2 }}>

    <Grid item xs={12} sm={6}>
      <DateInput
        control={control}
        name="payment_date"
        label="Payment Date"
        value={watch("payment_date")}
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <TextInput
        control={control}
        name="amount"
        label="Amount"
        type="number"
        value={watch("amount")}
        onChange={(e) => setValue("amount", e.target.value)}
      />
    </Grid>
  </Grid>

  <Grid container spacing={2} sx={{ mt: 2 }}>

    <Grid item xs={12} sm={6}>
      <SelectX
        label="Foreign Currency"
        options={fetchCurrency}
        control={control}
        name="foreign_currency"
        defaultValue={watch("foreign_currency")}
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <TextInput
        control={control}
        name="foreign_amount"
        type="number"
        label="Foreign Amount"
        value={watch("foreign_amount")}
        onChange={(e) => setValue("foreign_amount", e.target.value)}
      />
    </Grid>
  </Grid>


  <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
    <LoadingButton
      loading={formButtonStatus.loading}
      disabled={!isDirty||!isValid}
      variant="outlined"
      type="submit"
    >
      {formButtonStatus.label}
    </LoadingButton>
  </Grid>
</Grid>

            </form>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}


export default CreateTransactions
import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import SelectX from '../../project-portal/form/SelectX';
import { Employee } from '../../api/Endpoints/Employee';
import TextInput from '../../project-portal/form/TextInput';
import moment from 'moment';
import toast from 'react-hot-toast';
import { AssignUser } from '../../api/Endpoints/Assign';
import { LoadingButton } from '@mui/lab';

function AssignPopup({ editID, setEditId, itemId, UnassignId, setUnAssignId, onUpdate }) {

    const [open, setOpen] = useState(false);

    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Assign",
        loading: false,
        disabled: false,
    });

    const scheme = yup.object().shape({
        assign_note: !UnassignId && yup.string().required("Note is requrired"),
        assign_to: !UnassignId && yup.object().required("please select the user"),
        release_note: UnassignId && yup.string().required("Release Note is requrired"),
        category: yup.object(),
        transaction_id: yup.string(),
        purchased_date: yup.string(),
        purchased_price: yup.string(),
    });

    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: 'all' });

    const handleClose = () => {
        setTimeout(() => {
            setUnAssignId(0)
        }, 300);

        setEditId(0);
        setOpen(false);
        reset()
    };

    useEffect(() => {
        if (UnassignId > 0) {
            setOpen(true);
        } else if (editID === undefined) {
            setOpen(true);
        }
    }, [editID, UnassignId]);

    const fetchDetails = () => {
        setOpen(true);
    };

    const fetchEmployees = (e) => {
        return Employee.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== 'undefined') {
                return response.data.data.data;
            } else {
                return [];
            }
        });
    };



    const onSubmit = (data) => {

        setFormButtonStatus({ ...formButtonStatus, loading: true });



        let dataToSubmit = {
            assignee_id: data.assign_to?.id,
            assign_note: data.assign_note,
            item_id: itemId,
            release_note: data.release_note
        };


        let action;

        if (UnassignId > 0) {

            dataToSubmit.id = UnassignId
            action = AssignUser.unAssign(dataToSubmit)
        } else {
            action = AssignUser.assign(dataToSubmit)
        }
        action.then(response => {

            setFormButtonStatus({ label: "Assign", loading: false, disabled: false });
            if (response.data.error) {
                toast.error(response.data.message, { autoClose: 2000 });
                return
            }
            toast.success(response.data.message, { autoClose: 2000 });
            onUpdate()
            handleClose();

        }).catch(errors => {

            toast.error("Internal server error");
            setFormButtonStatus({ label: "Assign", loading: false, disabled: false });
            handleClose()
        })
    }


    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{ '& .MuiDialog-paper': { width: '500px', maxWidth: '500px' } }}
            >
                <DialogTitle textAlign={'center'}>{UnassignId > 0 ? "UNAssign User" : "Assign To User"}</DialogTitle>
                <DialogContent  >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {!UnassignId && (
                            <Grid sx={{ mt: 2 }} item xs={12}>
                                <SelectX
                                    label={"Assign To"}
                                    options={fetchEmployees}
                                    control={control}
                                    error={errors?.assign_to?.id ? errors?.assign_to?.id?.message : false}
                                    error2={errors?.assign_to?.message ? errors?.assign_to?.message : false}
                                    name={'assign_to'}
                                    defaultValue={watch('assign_to')}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} mt={2}>
                            {UnassignId ? (
                                <TextInput
                                    control={control}
                                    name="release_note"  // Ensure no extraneous characters
                                    label="Release Note"
                                    value={watch('release_note')}
                                    row={5}
                                    isMultiline
                                />
                            ) : (
                                <TextInput
                                    control={control}
                                    name="assign_note"
                                    label="Assign Note"
                                    value={watch('description')}
                                    row={5}
                                    isMultiline
                                />
                            )}

                        </Grid>
                        <Grid sx={{ pt: 2 }} item xs={12} display={'flex'} justifyContent={'end'}>      <DialogActions>


                            <Button onClick={handleClose} variant='outlined' color='error' sx={{ marginRight: 1 }}>Close</Button>
                            {UnassignId ? (
                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                    variant="outlined" type="submit">{"UNAssign"}</LoadingButton>
                            ) : (
                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                    variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                            )}
                        </DialogActions>
                        </Grid>
                    </form>
                </DialogContent>





            </Dialog>
        </div >
    );
}

export default AssignPopup;

import React, { useEffect, useState } from 'react';
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, Slide, TextField, Typography, Box, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import SelectX from '../../../form/SelectX'
import TextInput from '../../../form/TextInput'
import { ErrorMessage } from "@hookform/error-message";
import { LoadingButton } from "@mui/lab";
import { Departments } from '../../../../api/Endpoints/Departments';
import DateInput from '../../../form/DateInput';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { categories } from '../../../../api/Endpoints/Category';
import { Country } from '../../../../api/Endpoints/Country';
import { TaxCode } from '../../../../api/Endpoints/TaxCode';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({
    title: yup.string().required('Title is required'),
    code: yup.string().required('Code is required'),
    country: yup.object().required('Country is required'),
    SGST_percentage: yup.number().typeError('SGST percentage must be a number').required('SGST percentage is required').min(0, 'SGST percentage cannot be negative'),
    CGST_percentage: yup.number().typeError('CGST percentage must be a number').required('CGST percentage is required').min(0, 'CGST percentage cannot be negative'),
    IGST_percentage: yup.number().typeError('IGST percentage must be a number').required('IGST percentage is required').min(0, 'SGST percentage cannot be negative'),
    VAT_percentage: yup.number().typeError('VAT percentage must be a number').required('VAT percentage is required').min(0, 'VAT percentage cannot be negative'),
    description: yup.string()
});


const CreateTaxCode = (props) => {

    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [editValues, setEditValues] = useState({})
    const [refresh, setRefresh] = React.useState(false);



    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    const [alerts, setAlerts] = useState({
        type: '',
        message: '',
        active: false
    })



    const handleClickOpen = () => {
        props.onNew()
    };

    const handleClose = () => {
        props.setEditId()
        setOpen(false);
    };

    const onSubmit = (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true });
        setAlerts({ active: false, message: "Internal server error", type: "error" })

        let dataToSubmit = {
            id: props.editId,
            title: data?.title,
            description: data.description,
            country_id: data.country.id,
            code: data.code,
            SGST_percentage: data.SGST_percentage,
            CGST_percentage: data.CGST_percentage,
            IGST_percentage: data.IGST_percentage,
            VAT_percentage: data.VAT_percentage
        }

        console.log(dataToSubmit);
        let action;

        if (props.editId > 0) {
            console.log(dataToSubmit);
            action = TaxCode.update(dataToSubmit)
        } else {
            console.log(dataToSubmit);
            action = TaxCode.add(dataToSubmit)
        }
        action.then((response) => {
            console.log(response.data?.data);
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true })
            setAlerts({ active: true, message: response.data.message, type: response.data.status })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false })
            toast.success(response.data.message)
            props.onUpdate()
            setOpen(false)
            setTimeout(() => { setAlerts({}) }, 2000)
        }).catch(errors => {
            console.log(errors);
            setOpen(false)
            toast.error("server error")
            setAlerts({ active: true, message: "Internal server error", type: "error" })
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        })

    }

    const fetchDetails = () => {
        setLoading(true)
        console.log({id: props.editId});
        
        
        TaxCode.getDetails({ id: props.editId }).then((response) => {
            console.log({tax:response?.data?.data});
            let data = response.data.data
            setValue('title', data.title)
            setValue('description', data?.description);
            setValue('code', data?.code);
            setValue('SGST_percentage', data?.SGST_percentage);
            setValue('CGST_percentage', data?.CGST_percentage);
            setValue('IGST_percentage', data?.IGST_percentage);
            setValue('VAT_percentage', data?.VAT_percentage);
            setValue('country', data?.country);
            setLoading(false)
        })
    }


    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchDetails()
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            setOpen(true);
        }

    }, [])

    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                {props.icon ? <AddIcon style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }} /> : "Create Tax Code"}
            </Button>
            <Dialog
                open={open}
                PaperProps={{ sx: { width: "50%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit Tax Code" : "Create Tax Code"}</DialogTitle>

                <DialogContent>

                    {loading ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <form onSubmit={handleSubmit(onSubmit)}>

                        <ErrorMessage
                            errors={errors}
                            name="multipleErrorInput"
                            render={({ messages }) =>
                                messages &&
                                Object.entries(messages).map(([type, message]) => (
                                    <p key={type}>{message}</p>
                                ))
                            }
                        />

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextInput control={control}
                                    name="code"
                                    label="Code"
                                    value={watch('code')} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput control={control}
                                    name="title"
                                    label="Title"
                                    value={watch('title')} />
                            </Grid>

                            <Grid item xs={12}>
                                <SelectX
                                    label="Country"
                                    options={fetchCountry}
                                    control={control}
                                    name={'country'}
                                    defaultValue={watch('country')}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextInput control={control}
                                    name="SGST_percentage"
                                    label="SGST Percentage"
                                    type="number"
                                    
                                    value={watch('SGST_percentage')} />
                            </Grid>

                            <Grid item xs={6}>
                                <TextInput control={control}
                                    name="CGST_percentage"
                                    label="CGST Percentage"
                                    type="number"
                                    
                                    value={watch('CGST_percentage')} />
                            </Grid>

                            <Grid item xs={6}>
                                <TextInput control={control}
                                    name="IGST_percentage"
                                    label="IGST Percentage"
                                    type="number"
                                    
                                    value={watch('IGST_percentage')} />
                            </Grid>

                            <Grid item xs={6}>
                                <TextInput control={control}
                                    name="VAT_percentage"
                                    label="VAT Percentage"
                                    type="number"
                                    
                                    value={watch('VAT_percentage')} />
                            </Grid>

                            <Grid item xs={12}>
                                <InputLabel sx={{
                                    color: "black",
                                    pb: 1
                                }}>Description </InputLabel>
                                <TextField
                                    {...register('description')}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={2}
                                    sx={{ width: '100%', }}
                                    // required
                                />
                            </Grid>
                        </Grid>

                        <Grid sx={{ pt: 2 }} item xs={12}>
                            <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                variant="outlined" type="submit">{formButtonStatus.label}</LoadingButton>
                        </Grid>
                    </form>
                    )}

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>

            </Dialog>
        </div>
    )
}

export default CreateTaxCode;


const fetchCountry = (e) => {
    return Country.get({ keyword: e }).then(response => {
        if (typeof response.data.data !== "undefined") {
            return response.data.data
        } else {
            return [];
        }
    })
}
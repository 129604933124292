import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const workOrders = {
  ready: [
    { id: 1, name: "Website development - Part 1", amount: 12000 },
    { id: 2, name: "Digital Marketing - Nov 2024", amount: 7000 },
    { id: 3, name: "SEO for October 2024", amount: 6500 },
  ],
  notReady: [
    { id: 4, name: "Website development - Part 2", amount: 120000 },
    { id: 5, name: "Brochure development - final", amount: 6500 },
  ],
};

const AddWorkOrder = () => {
  const [open, setOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [workOrderType, setWorkOrderType] = useState("CLIENT"); // Default to CLIENT

  const handleOpen = (order) => {
    setSelectedOrder(order);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOrder(null);
  };

  return (
    <Box sx={{ paddingLeft: 3 }}>
      <Button onClick={() => window.history.back()}>Back</Button>
       <Box sx={{ p: 0 }}>
          {/* <Typography variant="h4" gutterBottom>
            ADD WORK ORDER TO INVOICE
          </Typography> */}
          <Box sx={{mb:3}}>
            <Typography variant="h5" sx={{ mt: 2 }}>
              Invoice Date: 31 Jan 2024
            </Typography>
            <Typography variant="h5" sx={{ mt: 1 }} >
              Invoice ID: INV-001
            </Typography>
            <Typography variant="h5" sx={{ mt: 1 }} >
              Invoice Title: Construction Services
            </Typography>
            <Typography variant="h5" sx={{ mt: 1 }} >
              Client Name: Skyline Builders Pvt Ltd
            </Typography>
            <Typography variant="h5" sx={{ mt: 1 }} >
              Client Profile: Construction Company
            </Typography>
            {/* <Typography variant="h5" sx={{ mt: 2, textDecoration: "underline" }}>
              INVOICE LINE ITEMS (WORK ORDERS)
            </Typography> */}
          </Box>
          </Box>
      <Typography variant="h4" gutterBottom>
        ADD WORK ORDER TO INVOICE
      </Typography>
     

      {/* READY TO INVOICE */}
      <Typography variant="h6" sx={{ textDecoration: "underline" }}>
        READY TO INVOICE:
      </Typography>
      <List>
        {workOrders.ready.map((order) => (
          <ListItem
            key={order.id}
            secondaryAction={
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpen(order)}
              >
                <AddIcon color={"inherit"} />
              </Button>
            }
          >
            <ListItemText
              primary={order.name}
              secondary={`INR ${order.amount}`}
            />
          </ListItem>
        ))}
      </List>

      {/* NOT READY TO INVOICE */}
      <Typography variant="h6" sx={{ mt: 3, textDecoration: "underline" }}>
        NOT READY TO INVOICE:
      </Typography>
      <List>
        {workOrders.notReady.map((order) => (
          <ListItem
            key={order.id}
            secondaryAction={
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpen(order)}
              >
                <AddIcon color={"inherit"} />
              </Button>
            }
          >
            <ListItemText
              primary={order.name}
              secondary={`INR ${order.amount}`}
            />
          </ListItem>
        ))}
      </List>

      {/* MODAL */}
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>ADD WORK ORDER TO INVOICE</DialogTitle>
        <DialogContent>
          {selectedOrder && (
            <Box>
              <TextField
                fullWidth
                margin="normal"
                label="Item"
                value={selectedOrder.name}
                disabled
              />
              <TextField
                fullWidth
                margin="normal"
                label="Description"
                placeholder="Enter description"
              />
              <TextField
                fullWidth
                margin="normal"
                label="Amount"
                value={selectedOrder.amount}
                disabled
              />
              <Select
                fullWidth
                margin="normal"
                value={workOrderType}
                onChange={(e) => setWorkOrderType(e.target.value)}
              >
                <MenuItem value="CLIENT">Client</MenuItem>
                <MenuItem value="INTERNAL">Internal</MenuItem>
              </Select>

              {workOrderType === "CLIENT" && (
                <>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="PO Number"
                    placeholder="Enter PO Number"
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="CGST"
                    placeholder="Enter CGST"
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="SGST"
                    placeholder="Enter SGST"
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="IGST"
                    placeholder="Enter IGST"
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="VAT"
                    placeholder="Enter VAT"
                  />
                </>
              )}

              {workOrderType === "INTERNAL" && (
                <TextField
                  fullWidth
                  margin="normal"
                  label="Invoiced To"
                  placeholder="Enter department name"
                />
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button variant="contained" color="primary">
            ADD WORK ORDER TO INVOICE
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddWorkOrder;

import PageHeader from "../../common/page-header";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Grid,
  TextField
} from "@mui/material";
import { blue, red } from "@mui/material/colors";
import DetailView from "./DetailView";
import DeleteMoadal from "./deleteModal";
import CreateTransactions from "./create";
import { TransactionsApi } from "../../../../api/Endpoints/Transactions";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import HistoryView from "./HistoryView";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LedgerView from "./LedgerView";
import SelectX from "../../../form/SelectX";
import { useForm } from "react-hook-form";
import { Currency } from "../../../../api/Endpoints/Currency";

const Transactions = () => {
  const [refresh, setRefresh] = useState(false);
  const [editId, setEditId] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsDetails, setrowsDetails] = useState({});
  const [detailViewId, setDetailViewId] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [historyViewId,setHistoryViewId]=useState(null);
  const [ledgerViewId,setLedgerViewId]=useState(null);
  const [openLedgerView,setOpenLedgerView]=useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [prevTransaction,setPrevTransaction]=useState({
    id: null,
    title: null,
    account_business_accounts_id: null,
    account_payment_channels_id: null,
    account_tax_accounts_id: null,
    default_currency_id: null,
    amount: null,
    transaction_date: null,
    transaction_type: null,
    description: null
  });

  const handleCreate = () => {
    setEditId(0);
    setRefresh(Math.random);
  };

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const handleUpdate = (details) => {
    // handleCreate()
    // setEditId()
    // console.log(editId);
    // setrowsDetails(details)
    // console.log(details);
    setPageNumber();
    setPageNumber(0);
  };

  const TitleB = {
    color: "blue",
    cursor: "pointer"
  };

  const fetchList = () => {
    setLoading(true);
    TransactionsApi.get({ page: Number(pageNumber) + 1, keyword: searchKeyword }).then((response) => {
      console.log(response.data.data);
      setList(response.data.data);
      setLoading(false);
    });
  };
  const fetchCurrency = (e) => {
    return Currency.get({ keyword: e }).then((response) => {
      console.log(response);

      if (typeof response.data.data !== "undefined") {
        return response.data.data;
      } else {
        return [];
      }
    });
  };

  const handleEdit = (id) => {
    setRefresh(Math.random);
    setEditId(parseInt(id));
  };

  const deleteItem = (id) => {
    setDeleteId(id);
  };
  const {
    watch,
    formState: { errors },
    control,
    setValue,
    reset
  } = useForm();
  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 450,
      renderCell: (params) => (
        <p style={TitleB} onClick={() => DetailViewOpen(params.id)}>
          {params.row.title}
        </p>
        // <p  onClick={viewTabs}>{params.row.title}</p>
      )
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => (
        <>
          {params.row.amount} {params.row.default_currency?.name}
        </>
      )
    },
    {
      field: "taxaccount",
      headerName: "Transaction Date",
      width: 150,
      renderCell: (params) => (
        <>{moment(params.row.transaction_date).format("DD-MM-YYYY")}</>
      )
    },
    {
      field: "edit",
      headerName: "",
      width: 100,
      renderCell: (params) => (
        <Button onClick={() => handleEdit(params.id)}>
          <EditIcon sx={{ fontSize: 19 }} />
        </Button>
      )
    },
    {
      field: "delete",
      headerName: "",
      width: 100,
      renderCell: (params) => {
        const createdAt = moment(params.row.created_at); 
        const now = moment();
        const isDeletable = now.diff(createdAt, 'hours') < 24; 
    
        return isDeletable ? (
          <Button onClick={() => deleteItem(params.id)} color="error">
            <DeleteIcon sx={{ color: "red", fontSize: 19 }} />
          </Button>
        ) : null; 
      },
    },
    
    {
      field: "history",
      headerName: "",
      width: 100,
      renderCell: (params) => {
        return (
          <Button onClick={() => HistoryViewOpen(params.id)}>
            <WorkHistoryIcon color={"action"} sx={{ fontSize: 19 }} />
          </Button>
        );
      },
    },{
      field:'ledger',
      headerName:'',
      width:100,
      renderCell:(params)=>{
        return (
          <Button onClick={() => LedgerViewOpen(params.id)}>
            <AccountBalanceWalletIcon color={"info"} sx={{ fontSize: 19 }} />
          </Button>
        );
      }
    }
  ];

  const DetailViewOpen = (id) => {
    setDetailViewId(id);
  };

  const HistoryViewOpen = (id) => {
    setHistoryViewId(id);
  }

  const LedgerViewOpen=(id)=>{
    setLedgerViewId(id);
    setOpenLedgerView(true);
    console.log(openLedgerView);
    
    
  }

  useEffect(() => {
    fetchList();

  }, [pageNumber,searchKeyword]);

  useEffect(() => {
    console.log(openLedgerView);
    
  },[openLedgerView])

  return (
    <>
      {detailViewId && (
        <DetailView id={detailViewId} handleClose={setDetailViewId} />
      )}

      {historyViewId && (
        <HistoryView id={historyViewId} handleClose={setHistoryViewId} />
      )}

      {deleteId && (
        <DeleteMoadal
          id={deleteId}
          handleOpen={setDeleteId}
          refresh={handleUpdate}
          statement={"Transaction"}
        />
      )}

      {ledgerViewId && (
        <LedgerView id={ledgerViewId} setLedgerViewId={setLedgerViewId} openLedgerView={openLedgerView}  setOpenLedgerView={setOpenLedgerView} />
      )}

    

<Card sx={{ p: 2, mb: 2 }}>
  <Card sx={{display:'flex',justifyContent:"space-between"}}>
  <PageHeader title="Transactions" />
          <CreateTransactions
            key={refresh}
            onNew={handleCreate}
            editId={editId}
            setEditId={setEditId}
            setPrevTransaction={setPrevTransaction}
            prevTransaction={prevTransaction}
            onUpdate={handleUpdate}
          />
  </Card>
  <Box sx={{ mt: 2 }}>
    <Grid container spacing={2}>
      {/* First Row */}
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="Search by Title"
          name="searchTitle"
          onChange={(e)=>setSearchKeyword(e.target.value)}
          sx={{ "& .MuiInputBase-root": { height: "40px" } }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="Search by Reference"
          name="searchReference"
          sx={{ "& .MuiInputBase-root": { height: "40px" } }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SelectX
          key={refresh * 0.2}
          placeholder="Select Category"
          options={fetchCurrency}
          control={control}
          name="category"
          defaultValue={watch("category")}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
      <SelectX
          key={refresh * 0.2}
          placeholder="Select Currency"
          options={fetchCurrency}
          control={control}
          name="currency"
          defaultValue={watch("currency")}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="Min Amount"
          name="minAmount"
          type="number"
          sx={{ "& .MuiInputBase-root": { height: "40px" } }}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="Max Amount"
          name="maxAmount"
          type="number"
          sx={{ "& .MuiInputBase-root": { height: "40px" } }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="Start Date"
          name="startDate"
          type="date"
          InputLabelProps={{ shrink: true }}
          sx={{ "& .MuiInputBase-root": { height: "40px" } }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          label="End Date"
          name="endDate"
          type="date"
          InputLabelProps={{ shrink: true }}
          sx={{ "& .MuiInputBase-root": { height: "40px" } }}
          fullWidth
        />
      </Grid>
    
      
    </Grid>
  </Box>
</Card>



      <Card sx={{ m: 2 }} variant="outlined">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {list && (
          <DataGrid
            paginationMode="server"
            rows={list?.data}
            columns={columns}
            page={pageNumber}
            pageSize={10}
            rowHeight={30}
            rowCount={list?.total}
            rowsPerPageOptions={[10]}
            onPageChange={handlePageChange}
            autoHeight
            density={"comfortable"}
          />
        )}
      </Card>
    </>
  );
};

export default Transactions;

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import SelectX from "../../../form/SelectX";
import TextInput from "../../../form/TextInput";
import { ErrorMessage } from "@hookform/error-message";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { toast } from "react-hot-toast";
import { brands } from "../../../../api/Endpoints/Brands";
import RadioInput from "../../../form/RadioInput";
import { Auth } from "../../../../api/Endpoints/Auth";
import { TransactionsCategory } from "../../../../api/Endpoints/TranscactionCategory";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({
  category_name: yup.string().required("Category Name is required"),
  status:yup.number().required("Status is required").oneOf([0,1]).typeError("Status must be a number"),
});

const CreateTransactionCategory = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isValid },
    control,
    setValue,
    getValues,
    reset,
  } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [refresh, setRefresh] = React.useState(false);

  console.log({errors});
  

  const [formButtonStatus, setFormButtonStatus] = useState({
    label: "Submit",
    loading: false,
    disabled: false,
  });

  const [alerts, setAlerts] = useState({
    type: "",
    message: "",
    active: false,
  });

  const handleClickOpen = () => {
    props.onNew();
  };

  const handleClose = () => {
    props.setEditId();
    setOpen(false);
  };
  console.log(props?.editId);

  const onSubmit = (data) => {
    console.log("Submitted");

    console.log(data);
    setFormButtonStatus({ ...formButtonStatus, loading: true });
    setAlerts({
      active: false,
      message: "Internal server error",
      type: "error",
    });

    let dataToSubmit =  {
          id: props.editId,
          category_name: data.category_name,
          description: data?.description,
          status: data.status,
          added_date: data.added_date||moment(new Date()).format("YYYY-MM-DD"),
        }

    let action;

    if (props.editId > 0) {
      console.log("edit", props.editId);
      console.log(dataToSubmit);
      action = TransactionsCategory.update(dataToSubmit);
    } else {
      console.log(dataToSubmit);
      action = TransactionsCategory.add(dataToSubmit);
    }
    action
      .then((response) => {
        console.log(response.data, "error in rands");
        setFormButtonStatus({
          label: "Submitted",
          loading: false,
          disabled: true,
        });
        setAlerts({
          active: true,
          message: response.data.message,
          type: response.data.status,
        });
        setFormButtonStatus({
          label: "Create",
          loading: false,
          disabled: false,
        });
        toast.success(response.data.message);
        props.onUpdate();
        setOpen(false);
        setTimeout(() => {
          setAlerts({});
        }, 2000);
      })
      .catch((errors) => {
        console.log(errors, "eror in updating");

        setOpen(false);
        toast.error("server error");
        setAlerts({
          active: true,
          message: "Internal server error",
          type: "error",
        });
        setFormButtonStatus({
          label: "Create",
          loading: false,
          disabled: false,
        });
      });
  };

  const fetchDetails = () => {
    setLoading(true);
    TransactionsCategory.getDetails({ id: props.editId }).then((response) => {
      console.log("response", response);
      let data = response.data.data;
      console.log({ data, id: props?.editId, category: data[0].category_name });

      setValue("category_name", data[0].category_name);
      setValue("description", data[0].description);
      // setValue("status", data.status ? "true" : "false");
      setValue("status", data[0].status);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (parseInt(props.editId) > 0) {
      fetchDetails();
      setOpen(true);
    } else if (Number(props.editId) === 0) {
      setOpen(true);
    }
  }, []);

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        {props.icon ? (
          <AddIcon
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          />
        ) : (
          "Create Transaction Category"
        )}
      </Button>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "50%",
            height: "100%",
            position: "fixed",
            right: 0,
            top: 0,
            bottom: 0,
            m: 0,
            p: 0,
            borderRadius: 0,
            maxHeight: "100%",
          },
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {parseInt(props.editId) > 0
            ? "Edit Transaction Category"
            : "Create Transaction category"}
        </DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ErrorMessage
              errors={errors}
              name="multipleErrorInput"
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              }
            />

            <Grid sx={{}} item xs={12}>
              <TextInput
                control={control}
                placeholder="Enter Category Name"
                name="category_name"
                label="Category Name"
                value={watch("category_name")}
              />
             
            </Grid>

            {/* <Grid sx={{ pt: 2 }} item xs={12}>
              <InputLabel
                sx={{
                  color: "black",
                  pb: 1
                }}
              >
                Description
              </InputLabel>
              <TextField
                {...register("description")}
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                sx={{ width: "100%" }}
              />
            </Grid> */}
            <Grid sx={{ pt: 2 }}>
              <InputLabel
                sx={{
                  color: "black",
                  pb: 1,
                }}
              >
                Status
              </InputLabel>
              <RadioInput
                name="status"
                control={control}
                label="Status"
                value={props.editId ? watch("status") : true}
                options={[
                  { value: 1, label: "Active" },
                  { value: 0, label: "Not Active" },
                ]}
              />
            </Grid>
            <Grid sx={{ pt: 2 }} item xs={12}>
              <LoadingButton
                loading={formButtonStatus.loading}
                disabled={!isValid || !isDirty}
                variant="outlined"
                type="submit"
              >
                {formButtonStatus.label}
              </LoadingButton>
            </Grid>
          </form>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateTransactionCategory;

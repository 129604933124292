import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Divider } from '@mui/material';

function AssignNotesPopup({ Open, setOpen, assign_note, release_note, cost, fromMaintanceTab }) {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={Open}
            onClose={handleClose}
            sx={{ '& .MuiDialog-paper': { width: '500px', maxWidth: '500px', height: '500px' } }}
        >
            <DialogTitle textAlign="center">Note</DialogTitle>
            <DialogContent>
                {cost && (
                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                        Cost :
                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                            {cost}
                        </Typography>
                    </Typography>
                )}
                {fromMaintanceTab && (
                    <Divider sx={{ my: 2 }} />
                )}
                <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                    Assign Note:
                    <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                        {assign_note}
                    </Typography>
                </Typography>

                <Divider sx={{ my: 2 }} />

                {release_note && (
                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                        {fromMaintanceTab ? "Return Note:" : "Release Note:"}
                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                            {release_note}
                        </Typography>
                    </Typography>
                )}

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="error">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AssignNotesPopup;

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { categories } from "../../../../api/Endpoints/Category";
import { VendorsApi } from "../../../../api/Endpoints/Vendors";
import { VendorPayments } from "../../../../api/Endpoints/VendorPayments";
import { TaxAccount } from "../../../../api/Endpoints/TaxAccount";
import { SubscriptionsApi } from "../../../../api/Endpoints/Subscriptions";
import { TransactionsApi } from "../../../../api/Endpoints/Transactions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    boxShadow: "1px 2px 2px 2px rgba(0, 0, 0, 0.3)",
  },
  title: {
    fontWeight: "bold",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  select: {
    minWidth: 200,
  },
}));

const HistoryView = (props) => {
  const [history, setHistory] = useState();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    props.handleClose(false);
  };

  useEffect(() => {
    const fetchHistory = async () => {
      setLoading(true);
      try {
        const historyList = await TransactionsApi.historyView({ transaction_id: props.id });
        setHistory(historyList?.data?.data?.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchHistory();
  }, [props.id]);

  return (
    <>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            width: "70%",
            height: "100%",
            position: "fixed",
            right: 0,
            top: 0,
            bottom: 0,
            m: 0,
            p: 0,
            borderRadius: 0,
            maxHeight: "100%",
          },
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>TRANSACTION EDIT HISTORY</DialogTitle>
        <DialogContent>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer component={Paper} sx={{ maxHeight: '100%', overflow: 'auto' }}>
              <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 700 }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ padding: '10px' }}>Date</TableCell>
                    <TableCell sx={{ padding: '10px' }}>Change By</TableCell>
                    <TableCell sx={{ padding: '10px' }}>Field</TableCell>
                    <TableCell sx={{ padding: '10px' }}>Old Value</TableCell>
                    <TableCell sx={{ padding: '10px' }}>New Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ padding: '10px' }}>{item?.edit_date}</TableCell>
                      <TableCell sx={{ padding: '10px' }}>{item?.changed_user?.name}</TableCell>
                      <TableCell sx={{ padding: '10px' }}>{item?.field_name}</TableCell>
                      <TableCell sx={{ padding: '10px' }}>{item?.old_value}</TableCell>
                      <TableCell sx={{ padding: '10px' }}>{item?.new_value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HistoryView;

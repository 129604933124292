import React, { useEffect, useState } from "react";
import PageHeader from "../../common/page-header";
import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Employee } from "../../../../api/Endpoints/Employee";
import { VendorAPI } from "../../../../api/Endpoints/Vendor";
import { Account } from "../../../../api/Endpoints/Account";
import { DataGrid } from "@mui/x-data-grid";

function Ledger() {
  const [selectedType, setSelectedType] = useState("client");
  const [selectedList, setSelectedList] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [clients, setClients] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [profile, setProfile] = useState(null);

  const rows = [
  {
    id: 1,
    date: "2023-01-01",
    title: "Invoice 1",
    credit: 100,
    debit: 0,
    type: "Invoice",
    balance: 100,
    remarks:'remark'
  },
  {
    id: 2,
    date: "2023-02-01",
    title: "Invoice 2",
    credit: 0,
    debit: 50,
    type: "Invoice",
    balance: 50,
    remarks:'remark'
  },
  {
    id: 3,
    date: "2023-03-01",
    title: "Invoice 3",
    credit: 0,
    debit: 75,
    type: "Invoice",
    balance: 25,
    remarks:'remark'
  },
  ];

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 150,
    },
    {
      field: "title",
      headerName: "Title",
      width: 300,
    },
    {
      field: "credit",
      headerName: "Credit",
      width: 100,
    },
    {
      field: "debit",
      headerName: "Debit",
      width: 100,
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
    },
    {
        field:'balance',
        headerName:'Balance',
        width:100
    },
    {
        field:'remarks',
        headerName:'Remarks',
        width:200
    }
  ];

  const fetchEmployees = async () => {
    const { data: employee } = await Employee.get();
    return employee?.data?.data;
  };

  const fetchVendors = async () => {
    const { data: vendor } = await VendorAPI.get();
    return vendor?.data?.data;
  };

  const fetchClients = async () => {
    const { data: client } = await Account.get();
    return client?.data?.data;
  };

  useEffect(async () => {
    const [employee, vendor, client] = await Promise.all([
      fetchEmployees(),
      fetchVendors(),
      fetchClients(),
    ]);
    setEmployees(employee);
    setSelectedList(employee);
    setVendors(vendor);
    setClients(client);
    console.log({ employee, vendor, client });
  }, []);

  const handleChange = (e) => {
    setSelectedType(e.target.value);

    switch (e.target.value) {
      case "CLIENT":
        setSelectedList(clients);
        break;
      case "VENDOR":
        setSelectedList(vendors);
        break;
      case "EMPLOYEE":
        setSelectedList(employees);
        break;
    }
  };

  const handleChange2 = (e) => {
    setProfile(e.target.value);
  };

  useEffect(() => {
    console.log({ selectedType, selectedList });
  }, [selectedType]);

  return (
    <Grid sx={{p:5}}>
      <PageHeader title="Ledger" />
      <Grid container spacing={2} sx={{ width: "100%", height: "auto" }} >
        <Grid item xs={12} sm={6} md={3} sx={{ my: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedType}
              onChange={handleChange}
              label="Select Type"
            >
              <MenuItem value="CLIENT">Client</MenuItem>
              <MenuItem value="VENDOR">Vendor</MenuItem>
              <MenuItem value="EMPLOYEE">Employee</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ my: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Profile</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={profile}
              onChange={handleChange2}
              label="Select Profile"
            >
              {selectedList?.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.name || item.account_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ my: 2 }}>
          <FormControl fullWidth>
            <DatePicker
              label="From Date"
              value={startDate}
              renderInput={(params) => <TextField {...params} />}
              onChange={(newValue) => setStartDate(newValue)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ my: 2 }}>
          <FormControl fullWidth>
            <DatePicker
              label="To Date"
              value={endDate}
              renderInput={(params) => <TextField {...params} />}
              onChange={(newValue) => setEndDate(newValue)}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Card sx={{ m: 0 }} variant="outlined">
      <Box sx={{ height: '100%', width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          rowCount={rows.length}
          disableRipple
          autoHeight
          density="comfortable"
          paginationMode="server"
          
        />
      </Box>
    </Card>
    </Grid>
  );
}

export default Ledger;

import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, Slide } from '@mui/material';
import TextInput from '../../../../form/TextInput';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

// Slide transition for the dialog
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddLIstItems = ({ open, setOpen, ID, setID, invoicetab }) => {
    const validationSchema = yup.object().shape({
        bill_id: yup.string().required('Bill ID is required'),
        title: yup.string().required('Line title is required'),
        amount: yup.number().required('Amount is required').positive('Amount must be positive'),
    });

    const { handleSubmit, formState: { errors }, control, watch } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    const handleClose = () => {
        setOpen(false);
        setID(null);
    };

    const onSubmit = (data) => {
        // Set loading state
        setFormButtonStatus({
            ...formButtonStatus,
            loading: true
        });

        // Here you would typically send the data to your API
        console.log('Form submitted with data:', data);

        // Simulate API call completion
        setTimeout(() => {
            setFormButtonStatus({
                label: "Submitted",
                loading: false,
                disabled: true
            });
            
            // Close the dialog after submission
            setTimeout(() => {
                handleClose();
                setFormButtonStatus({
                    label: "Submit",
                    loading: false,
                    disabled: false
                });
            }, 1000);
        }, 1500);
    };

    return (
        <div>
            <Dialog 
                open={open} 
                onClose={handleClose} 
                PaperProps={{ 
                    sx: { 
                        width: "50%", 
                        height: "100%", 
                        position: "fixed", 
                        right: 0, 
                        top: 0, 
                        bottom: 0, 
                        m: 0, 
                        p: 0, 
                        borderRadius: 0, 
                        maxHeight: '100%' 
                    } 
                }}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="add-list-items-dialog"
            >
                <DialogTitle>{ID ? "Edit Line Item" : "Add Line Item"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container padding={1} spacing={2}>
                           
                            
                            <Grid item xs={12}>
                                <TextInput 
                                    control={control}
                                    name="title"
                                    label="Line Title"
                                    error={errors?.title?.message}
                                />
                            </Grid>
                            
                            <Grid item xs={6}>
                                <TextInput 
                                    control={control}
                                    name="amount"
                                    label="Amount"
                                    type="number"
                                    error={errors?.amount?.message}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput 
                                    control={control}
                                    name="bill_id"
                                    label="Bill ID"
                                    error={errors?.bill_id?.message}
                                />
                            </Grid>
                            
                            <Grid item xs={12}>
                                <TextInput 
                                    control={control}
                                    name="description"
                                    label="Description"
                                    isMultiline
                                    row={4}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', p: 2 }}>
                    <Box>
                        <Typography>Total: {watch('amount') || 0}</Typography>
                    </Box>
                    <Box>
                        <Button onClick={handleClose} variant="outlined" color="error">
                            Close
                        </Button>
                        {ID == null && (
                            <LoadingButton 
                                loading={formButtonStatus.loading} 
                                disabled={formButtonStatus.disabled}
                                variant="outlined" 
                                type="submit" 
                                onClick={handleSubmit(onSubmit)}
                                sx={{ ml: 1 }}
                            >
                                {formButtonStatus.label}
                            </LoadingButton>
                        )}
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddLIstItems;
